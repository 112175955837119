/* ----------------------------------------------------------------------------------------------------- */
/* component shows a dialog for errors                                                                   */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState }            from "react";
import PropTypes                      from 'prop-types';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dialog                         from '@mui/material/Dialog';
import DialogTitle                    from '@mui/material/DialogTitle';
import Typography                     from '@mui/material/Typography';

import '../css/common.css';
const common = require('../lib/common');
const ether  = require('../lib/ether');


// props.severity: error | warning | info | success
// props.closeAction: callback when user closes the dialog. must at least clear the message.
const MessageDialog = (props) => {
    const title = props.title;
    const message = props.message;
    const closeAction = props.closeAction;
    const isOpen = !!props.message ? true : false;

    const onClose = () => {
        console.log("ErrorDialog: got onClose()");
        if (!!closeAction) {
            closeAction();
        }
    };


    return (
            <Dialog onClose={onClose} open={isOpen} sx={{ "textAlign": "center",
                                                          "& .MuiDialog-paper": {borderRadius: "10px", "padding": "3em", "bgcolor": "#e0e0e0" } }} >
              <DialogTitle>{title}</DialogTitle>
              <Typography>{message}</Typography>
            </Dialog>
    );
}

MessageDialog.propTypes = {
    message: PropTypes.string.isRequired,
};

export default MessageDialog;
