import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios                             from "axios";

// needed for async when using bable
const regeneratorRuntime = require("regenerator-runtime");
const common = require('../lib/common');
const ether = require('../lib/ether');
const to = require('../lib/to');

export const ezProxyUrlPrefix  = "https://ez-swarm.com:4443/"

// note thunkAPI = { dispatch, getState, extra, rejectWithValue }
// where extra is the "extra argument" given to the thunk middleware on setup, if available
export const thunkInitConnection = createAsyncThunk(
    'connect/thunkInitConnection',
    async (arg, thunkAPI) => {
        console.log('thunkInitConnection: at least im in the thunk');
        let networkName = "";
        //let maticBalanceWei = "";
        //let daiBalanceWei   = "";
        return new Promise((resolve, reject) => {
            try {
                ether.initProvider('metamask')
                    .then(provider => {
                        if (!provider)
                            throw("provider is null");
                        common.setupWeb3Node(provider);
                        return(ether.init());
                    })
                    .then(network => {
                        networkName = network;
                        console.log('thunkInitConnection networkName = ' + networkName + ', address = ' + ether.account0);
                        // if we are supporting ENS
                        // return(ether.ensReverseLookup(ether.account0));
                        // else
                        return("");
                    })
                    .then(name => {
                    const addrStr = common.abbreviateAddrForEns(ether.account0, name, 10, 8);
                        thunkAPI.dispatch(setNetwork({ "networkName"    : networkName,
                                                       "address"        : ether.account0,
                                                       "displayAddress" : addrStr }));
                        thunkAPI.dispatch(thunkReadBalances());
                        thunkAPI.dispatch(thunkGetPostageCredit({ addr: ether.account0 }));
                        resolve('okey dokey');
                    })
                    .catch(err => {
                        console.error('thunkInitConnection err = ' + err);
                        thunkAPI.dispatch(setConnectionError(err.toString()));
                        reject(err);
                    });
            } catch (err) {
                console.error('thunkInitConnection cought err = ' + err);
                thunkAPI.dispatch(setConnectionError(err.toString()));
                reject(err);
            }
        });
    }
);

export const thunkReadBalances = createAsyncThunk(
    'connect/thunkReadBalances',
    async (arg, thunkAPI) => {
        console.log('thunkReadBalances: at least im in the thunk');
        let networkName = "";
        let nativeBalanceWei = "";
        let daiBalanceWei   = "";
        let pyusdBalanceWei   = "";
        return new Promise((resolve, reject) => {
            try {
                ether.getBalance(ether.account0, 'wei')
                    .then(balance => {
                        nativeBalanceWei = balance;
                        console.log('thunkReadBalances native balance = ' + nativeBalanceWei);
                        return(ether.getERC20Balance(ether.account0, ether.DAITokenAddr, 'wei'));
                    })
                    .then(balance => {
                        daiBalanceWei = balance;
                        console.log('thunkReadBalances dai balance = ' + daiBalanceWei);
                        return(ether.getERC20Balance(ether.account0, ether.PYUSDTokenAddr, 'wei'));
                    })
                    .then(balance => {
                        pyusdBalanceWei = balance;
                        console.log('thunkReadBalances pyusd balance = ' + pyusdBalanceWei);
                        thunkAPI.dispatch(setBalances({ nativeBalanceWei : nativeBalanceWei,
                                                        daiBalanceWei    : daiBalanceWei,
                                                        pyusdBalanceWei  : pyusdBalanceWei }));
                        resolve('okey dokey');
                    })
                    .catch(err => {
                        console.error('thunkReadBalances err = ' + err);
                        thunkAPI.dispatch(setConnectionError(err.toString()));
                        reject(err);
                    });
            } catch (err) {
                console.error('thunkReadBalances cought err = ' + err);
            }
        });
    }
);

// retrieve postage credit for a specific address from the ez-proxy
export const thunkRefreshPostageCredit = createAsyncThunk(
    'connect/thunkRefreshPostageCredit',
    async (arg, thunkAPI) => {
        console.log('thunkRefreshPostageCredit: at least im in the thunk');
        const state = thunkAPI.getState();
        const addr = arg.addr;
        let postageCredit = "0.00";
        let chain = state.connectData.network.toLowerCase();
        console.log('thunkRefreshPostageCredit: chain = ' + chain);
        const coin = state.connectData.isDaiNotPyusd ? 'dai' : 'pyusd';
        return new Promise((resolve, reject) => {
            try {
                console.log("url = " + ezProxyUrlPrefix + "refresh-credit?addr=" + addr + "&chain=" + chain + "&coin=" + coin);
                axios.get(ezProxyUrlPrefix + "refresh-credit?addr=" + addr + "&chain=" + chain + "&coin=" + coin)
                    .then((rsp) => {
                        const info = rsp.data;
                        const err = common.arrayify(info).length == 0 ? "No response from server" : info.err;
                        if (err) {
                            throw err;
                        } else {
                            const credit = info.credit;
                            console.log('thunkRefreshPostageCredit: credit = ' + credit);
                            thunkAPI.dispatch(setPostageCredit(credit));
                            resolve('okey dokey');
                        }
                    })
                    .catch(err => {
                        console.error('thunkRefreshPostageCredit err = ' + err);
                        thunkAPI.dispatch(setPostageCredit("0.00"));
                        thunkAPI.dispatch(setConnectionError(err.toString()));
                        reject(err);
                    });
            } catch (err) {
                console.error('thunkRefreshPostageCredit cought err = ' + err);
                thunkAPI.dispatch(setPostageCredit("0.00"));
                thunkAPI.dispatch(setConnectionError(err.toString()));
                reject(err);
            }
        });
    }
);

// retrieve postage credit for a specific address from the ez-proxy
export const thunkGetPostageCredit = createAsyncThunk(
    'connect/thunkGetPostageCredit',
    async (arg, thunkAPI) => {
        console.log('thunkGetPostageCredit: at least im in the thunk');
        const addr = arg.addr;
        let postageCredit = "0.00";
        return new Promise((resolve, reject) => {
            try {
                axios.get(ezProxyUrlPrefix + "check-credit?addr=" + addr)
                    .then((rsp) => {
                        const info = rsp.data;
                        const err = common.arrayify(info).length == 0 ? "No response from server" : info.err;
                        if (err) {
                            throw err;
                        } else {
                            const credit = info.credit;
                            console.log('thunkGetPostageCredit: credit = ' + credit);
                            thunkAPI.dispatch(setPostageCredit(credit));
                            resolve('okey dokey');
                        }
                    })
                    .catch(err => {
                        console.error('thunkGetPostageCredit err = ' + err);
                        thunkAPI.dispatch(setPostageCredit("0.0"));
                        thunkAPI.dispatch(setConnectionError(err.toString()));
                        thunkAPI.rejectWithValue(err);
                    });
            } catch (err) {
                console.error('thunkGetPostageCredit cought err = ' + err);
                thunkAPI.dispatch(setPostageCredit("0.0"));
                thunkAPI.dispatch(setConnectionError(err.toString()));
                thunkAPI.rejectWithValue(err);
            }
        });
    }
);


export const connectSlice = createSlice({
    name: 'connectData',
    initialState: {
        changeMarker: 0,
        beenInitialized: false,
        isDaiNotPyusd: true,
        network: '',
        address: '',
        displayAddress: '',
        postageCredit: null,
        nativeBalanceWei: 0,
        daiBalanceWei:    0,
        pyusdBalanceWei:  0,
        connectErrorStatus: '',
        creditRefreshPending: false,
    },
    reducers: {
        unInitConnection: (state, action) => {
            state.beenInitialized = false;
            state.error = '';
            state.network = '';
            state.address = '';
            state.displayAddress = '';
            state.postageCredit = null,
            ++state.changeMarker;
        },
        setConnectionError: (state, action) => {
            if (!!action.payload)
                console.error('setConnectionError setting err = ' + action.payload);
            state.connectErrorStatus = action.payload;
            ++state.changeMarker;
        },
        setNetwork: (state, action) => {
            const changed = (state.network        != action.payload.networkName ||
                             state.address        != action.payload.address     ||
                             state.displayAddress != action.payload.displayAddress);
            state.beenInitialized = true;
            state.network        = action.payload.networkName;
            state.address        = action.payload.address;
            state.displayAddress = action.payload.displayAddress;
            if (changed)
                ++state.changeMarker;
        },
        setBalances: (state, action) => {
            const changed = (state.nativeBalanceWei != action.payload.nativeBalanceWei ||
                             state.daiBalanceWei    != action.payload.daiBalanceWei    ||
                             state.pyusdBalanceWei  != action.payload.pyusdBalanceWei);
            state.nativeBalanceWei = action.payload.nativeBalanceWei;
            state.daiBalanceWei    = action.payload.daiBalanceWei;
            state.pyusdBalanceWei  = action.payload.pyusdBalanceWei;
            if (changed)
                ++state.changeMarker;
        },
        setPostageCredit: (state, action) => {
            const changed = (state.postageCredit != action.payload);
            state.postageCredit = action.payload;
            if (changed)
                ++state.changeMarker;
        },
        setIsDaiNotPyusd: (state, action) => {
            const changed = (state.isDaiNotPyusd != action.payload);
            state.isDaiNotPyusd = action.payload;
            if (changed)
                ++state.changeMarker;
        },
    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        // thunks will have X.pending, X.fulfilled, X.rejected
        [thunkRefreshPostageCredit.pending]:   (state, action) => { state.creditRefreshPending = true;  ++state.changeMarker; },
        [thunkRefreshPostageCredit.fulfilled]: (state, action) => { state.creditRefreshPending = false; ++state.changeMarker; },
        [thunkRefreshPostageCredit.rejected]:  (state, action) => { state.creditRefreshPending = false; ++state.changeMarker; },

    },
})

export const {
    unInitConnection,
    setConnectionError,
    setNetwork,
    setBalances,
    setPostageCredit,
    setIsDaiNotPyusd,
} = connectSlice.actions;

export const connectDataSelector = state => state.connectData;
export default connectSlice.reducer;
