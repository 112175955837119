/* ----------------------------------------------------------------------------------------------------- */
/* this is the viewpage for the Easy Swarm Gateway App                                                   */
/* on this page you can see a list of all of the content that you have uploaded.                         */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageDataSelector,
         reloadPage,
         setMode }                  from '../features/pageFeature';
import { uploadDataSelector,
         setLongTaskDoneInfo,
         unInitUploadFeature,
         thunkInitUploadFeature,
         thunkTopOffContent }       from "../features/uploadFeature";
import { contentDataSelector,
         setContentErrorStatus,
         unInitContentFeature,
         thunkInitContentFeature,
         thunkSetSort,
         thunkGetContent,
         thunkDelContent }          from "../features/contentFeature";
import { connectDataSelector,
         thunkInitConnection }      from "../features/connectFeature";
import TopMenuBar                   from './TopMenuBar';
import WaitIcon                     from './WaitIcon';
import CustomTheme                  from './CustomTheme';
import TxidProgressCard             from './TxidProgressCard';
import ErrorDialog                  from './ErrorDialog';
import MessageDialog                from './MessageDialog';
import GatewaysModal                from './GatewaysModal';
import LinearProgressWithLabel      from './LinearProgressWithLabel';

import '../css/common.css';
import '../css/viewPage.css';

const common = require('../lib/common');

/* ----------------------------------------------------------------------------------------------------- */
/* mui icons                                                                                             */
/* ----------------------------------------------------------------------------------------------------- */
import Icon from '@mdi/react';
import { mdiCircleOutline as SortNoneIcon              } from '@mdi/js';
import { mdiArrowUpThinCircleOutline as SortUpIcon     } from '@mdi/js';
import { mdiArrowDownThinCircleOutline as SortDownIcon } from '@mdi/js';
import { mdiDatabaseCogOutline as GatewaySettingIcon   } from '@mdi/js';

/* ----------------------------------------------------------------------------------------------------- */
/* mui container and friends                                                                             */
/* ----------------------------------------------------------------------------------------------------- */
import Box                      from '@mui/material/Box';
import Chip                     from '@mui/material/Chip';
import Container                from '@mui/material/Container';
import Grid                     from '@mui/material/Grid';
import List                     from '@mui/material/List';
import ListItem                 from '@mui/material/ListItem';
import ListItemText             from '@mui/material/ListItemText';
import TextField                from '@mui/material/TextField';
import Typography               from '@mui/material/Typography';
import { ThemeProvider }        from '@mui/material/styles';
import { positions }            from '@mui/system';

/* ----------------------------------------------------------------------------------------------------- */
/* mui forms                                                                                             */
/* ----------------------------------------------------------------------------------------------------- */
import Button                   from '@mui/material/Button';
import Tooltip                  from '@mui/material/Tooltip';
import Input                    from '@mui/material/Input';
import FilledInput              from '@mui/material/FilledInput';
import OutlinedInput            from '@mui/material/OutlinedInput';
import InputLabel               from '@mui/material/InputLabel';
import InputAdornment           from '@mui/material/InputAdornment';
import InputUnstyled            from '@mui/base/InputUnstyled';
import Switch                   from '@mui/material/Switch';
import FormHelperText           from '@mui/material/FormHelperText';
import FormControlLabel         from '@mui/material/FormControlLabel';
import FormControl              from '@mui/material/FormControl';
import FormLabel                from '@mui/material/FormLabel';
import TextareaAutosize         from '@mui/material/TextareaAutosize';

/* ----------------------------------------------------------------------------------------------------- */
/* mui accordion controls                                                                                */
/* ----------------------------------------------------------------------------------------------------- */
import { styled }                   from '@mui/material/styles';
import ArrowForwardIosSharpIcon     from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion                 from '@mui/material/Accordion';
import MuiAccordionSummary          from '@mui/material/AccordionSummary';
import MuiAccordionDetails          from '@mui/material/AccordionDetails';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

/* ----------------------------------------------------------------------------------------------------- */
/* mui effects                                                                                           */
/* ----------------------------------------------------------------------------------------------------- */
import Zoom                  from '@mui/material/Zoom';


let pageChangeMarkerPrev          = -1;
let uploadChangeMarkerPrev        = -1;
let connectChangeMarkerPrev       = -1;
let contentChangeMarkerPrev       = -1;
let viewChangeMarkerPrev          = -1;
//
let topOffMessageDivClass         = "gone";
let topOffProgressDivClass        = "hidden";
let continueButtonClass           = "gone";
let waitIconDivClass              = "hidden";
let viewOptionsDivClass           = "visible";
//
let expandedPanelId               = -1;
let expandedPanelHash             = "";
let content                       = [];
let gateways                      = [];
let selectedGatewayIdx            = 0;
//
let gatewaysModalMessage          = "";
let gatewaysModalTitle            = "";
let gatewaysModalHash             = "";
let gatewaysModalList             = [];
let onGatewaysModalClose          = null;

let activeTxid                    = "";
let txClock                       = "";
let errorMessage                  = "";
let modalMessage                  = "";
let modalTitle                    = "";
let messageContinuePage           = "landing";
let errorSeverity                 = "success";
let initDone                      = false;
const insufficientCreditsTip      = "You don't have sufficient credits";
const operationInProgressTip      = "Cannot top-off postage while an operation is in progress";
const expiredConnotExtentTip      = "Content is expired; it cannot be extended";
const contentIsExpiredTitle       = "Content is expired";
const contentIsExpiredMessage     = "This content is not available on SWARM anymore. You can delete it from the list; or " +
                                    "if you upload the same identical content again, it will take the place of this content " +
                                    "in the list."
const gatewaysModalUrlsTitle      = "Sample URL's for this Content";
const gatewaysModalUrlsMessage    = "You can retreive this content via any Swarm Gateway, (including your own!). Below " +
                                    "is a small sample of URL's for retreiving this content:" ;
const gatewaysModalSettingTitle   = "Select a Swarm Gateway for viewing content";
const gatewaysModalSettingMessage = "Once content is uploaded to Swarm, you can retreive it using any Swarm Gateway, " +
                                    "(including your own!). Select which gateway you would like to use when you click " +
                                    "on a content hash:";

//
let scrollTop               = 0;
// this is the height of the scroll-able contentList element, which displays the all the content items. height is in px.
// it is used to detect when the user has scrolled to the bottom of the list
const contentListMaxHeight  = 600;


function ViewPage() {
    const pageData = useSelector(pageDataSelector);
    const uploadData = useSelector(uploadDataSelector);
    const contentData = useSelector(contentDataSelector);
    const connectData = useSelector(connectDataSelector);
    const dispatch = useDispatch();

    const beenRendered = !!document.getElementById('rootGrid');
    const expandedListItemRef = useRef(null);

    // useEffect is called after rendering
    useEffect(() => {
        if (!!connectData.network) {
            console.log(" pending = " + contentData.pending + ", forwardDone = " + contentData.forwardDone);
            if (!uploadData.beenInitialized) {
                dispatch(setLongTaskDoneInfo( { doneInfo: "", milestone: "" } ));
                if (!uploadData.longTaskErrorStatus)
                    dispatch(thunkInitUploadFeature());
                else
                    console.log("ViewPage: other error prevents initialization! (" + uploadData.longTaskErrorStatus + ")");
            } else if (!contentData.beenInitialized) {
                content = [];
                initDone = false;
                dispatch(thunkInitContentFeature());
            } else if (!initDone && !contentData.contentError && !contentData.pending) {
                console.log("ViewPage: calling thunkGetContent");
                dispatch(thunkGetContent({ page: 0 }));
                initDone = true;
            } else {
                console.log("initDone = " + initDone + ", contentError = " + contentData.contentError);
                console.log("pending = " + contentData.pending + ", forwardDone = " + contentData.forwardDone);
                if (!!expandedListItemRef.current) {
                    console.log("ViewPage useEffect: expandedListItemRef");
                    //expandedListItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    expandedListItemRef.current.scrollIntoViewIfNeeded({ behavior: 'smooth', block: 'nearest' });
                }
            }
        }
    }, [ contentData.changeMarker, uploadData.beenInitialized ] );

    if (beenRendered) {
        console.log("pageMode = " + pageData.pageMode);
        if (pageData.pageMode == 'view' && ((pageChangeMarkerPrev    != pageData.changeMarker   ) ||
                                            (uploadChangeMarkerPrev  != uploadData.changeMarker ) ||
                                            (contentChangeMarkerPrev != contentData.changeMarker) ||
                                            (connectChangeMarkerPrev != connectData.changeMarker)) ) {
            console.log("contentChangeMarkerPrev = " + contentChangeMarkerPrev + ", " + contentData.changeMarker);
            pageChangeMarkerPrev    = pageData.changeMarker;
            uploadChangeMarkerPrev  = uploadData.changeMarker
            contentChangeMarkerPrev = contentData.changeMarker;
            connectChangeMarkerPrev = connectData.changeMarker;
            errorMessage = "";
            if (!connectData.network) {
                modalTitle = "Please Connect Your Wallet";
                modalMessage = "You must connect your wallet before you can view status";
                messageContinuePage = "landing";
            } else if (!!connectData.error) {
                errorMessage = connectData.error;
                errorSeverity = "error";
                console.log("StatusPagePage: connect error prevents initialization! " + connectData.error);
            } else if (!!connectData.network) {
                if(!initDone || !!contentData.pending) {
                    ;
                } else if (!!contentData.contentError) {
                    errorMessage = contentData.contentError;
                    errorSeverity = "error";
                    continueButtonClass = "gone";
                } else {
                    console.log("ViewPage: contentData.contentl.length = " + contentData.content.length);
                    content = contentData.content;
                    gateways = contentData.gateways;
                    console.log("ViewPage: gateways = " + JSON.stringify(gateways));
                    waitIconDivClass = (0 < uploadData.longTaskProgress && uploadData.longTaskProgress < 100) ? "visible" : "hidden";
                    const newExpirationOrError = ((uploadData.longTaskProgress == 0          || uploadData.longTaskProgress == 100  ) &&
                                                  (uploadData.longTaskErrorStatus.length > 0 || uploadData.longTaskDoneInfo.length > 0  ));
                    console.log("ViewPage: newExpirationOrError = " + newExpirationOrError);
                    if (newExpirationOrError) {
                        topOffMessageDivClass = "visible";
                        if (!!uploadData.longTaskErrorStatus) {
                            errorMessage = uploadData.longTaskErrorStatus;
                            errorSeverity = "error";
                            continueButtonClass = "gone";
                        } else {
                            errorMessage = "";
                            continueButtonClass = "visible";
                        }
                    } else {
                        errorMessage = "";
                        continueButtonClass = "gone";
                    }
                }
            }
        }
    }

    const handlePanelChange = (panel, hash) => (event, newExpanded) => {
        console.log("handlePanelChange: expandedPanelId = " + expandedPanelId + ", panel = " + panel +
                    ", newExpanded = " + newExpanded + ", hash = " + hash);
        if (expandedPanelId != panel) {
            expandedPanelId = panel;
            expandedPanelHash = hash;
            scrollTop = contentList.scrollTop;
            //dispatch(setContentTitle(""));
            //dispatch(setContentComment(""));
            console.log("handlePanelChange: calling thunkGetComments(" + hash + ")");
            //dispatch(thunkGetComments({ hash: hash, goForward: false }));
        } else {
            expandedPanelId = -1;
            expandedPanelHash = "";
        }
        dispatch(reloadPage(''));
    };

    const onScroll = (event) => {
        // contentList.scrollHeight is the virtual height of the contentList element.
        // contentList.scrollTop is the amount that has been scrolled off the top of the element; and
        // contentListMaxHeight is the display size of the element. so whem the user scrolls all the
        // way down: contentList.scrollTop + contentListMaxHeight = contentList.scrollHeight
        // if we are close to the end of the scroll (withing 20 pixels), then load new content
        if (!contentData.pending) {
            if (contentList.scrollHeight - (contentList.scrollTop + contentListMaxHeight ) < 20) {
                const pageSize = contentData.pageSize;
                const currentPage = Math.floor((content.length - 1) / pageSize);
                const maxPage = Math.floor((contentData.contentCount - 1) / pageSize);
                if (currentPage < maxPage) {
                    console.log("onScroll: load new content now; current page = " + currentPage + ", max = " + maxPage);
                    dispatch(thunkGetContent({ page: currentPage + 1 }));
                }
            }
        }
    }

    /* ----------------------------------------------------------------------------------------------------- */
    // list item for a single piece of content
    // props: key, hash, idx, title
    /* ----------------------------------------------------------------------------------------------------- */
    const ViewItem = (props) => {
        const idx = props.idx;
        const hash = props.hash;
        const stamp = props.stamp;
        const titleDecoded = decodeURI(props.title);
        const title = titleDecoded.length < 100 ? titleDecoded : titleDecoded.substring(0, 97) + "...";
        const isExpanded = (expandedPanelId == idx) ? true : false;
        const shortHash = common.abbreviateAddrForEns(hash, "", 40, 0, 64);
        const nowSeconds = new Date().getTime() / 1000;
        const isExpired = (nowSeconds > props.expiration);
        // toLocaleTimeString('en-US') => "1:15:30 AM"
        const exprTimeOfDay = new Date(props.expiration * 1000).toLocaleTimeString('en-US');
        // if less than 12 hours, display the time (not including seconds)
        const expiration = (nowSeconds < props.expiration && props.expiration - nowSeconds < 12*3600)
              ? "approx. " + exprTimeOfDay.slice(0, -6) + exprTimeOfDay.slice(-3)
              : new Date(props.expiration * 1000).toDateString();
        const listItemRef = !!isExpanded ? expandedListItemRef : null;

        let add1ButtonEnable    = false;
        let add10ButtonEnable   = false;
        let add100ButtonEnable  = false;
        let add1ButtonTip       = expiredConnotExtentTip;
        let add10ButtonTip      = expiredConnotExtentTip;
        let add100ButtonTip     = expiredConnotExtentTip;
        if (!isExpired) {
            if (0 < uploadData.longTaskProgress && uploadData.longTaskProgress < 100) {
                add1ButtonTip = add10ButtonTip = add100ButtonTip = operationInProgressTip;
            } else {
                add1ButtonTip = add10ButtonTip = add100ButtonTip = insufficientCreditsTip;
                const effectiveAmount = (connectData.postageCredit < 1) ? 0 :
                      (connectData.postageCredit < 10) ? 1 :  (connectData.postageCredit < 100) ? 10 : 100;
                switch (effectiveAmount) {
                case 100:
                    add100ButtonTip = "Click to add one hundred postage credits to this content. " +
                        "This would extend the lifetime of the content by a bit over twenty-four years.";
                    add100ButtonEnable = true;
                case 10:
                    add10ButtonTip = "Click to add ten postage credits to this content. " +
                        "This would extend the lifetime of the content by approximately two years, five months.";
                    add10ButtonEnable = true;
                case 1:
                    add1ButtonTip = "Click to add one postage credit to this content. " +
                        "This would extend the lifetime of the content by approximately three months.";
                    add1ButtonEnable = true;
                    break;
                default:
                    break;
                }
            }
        }

        const onHash = () => (event) => {
            // open a window to the hash content
            //const elem = document.getElementById('url-input');
            //console.log('in onHash! ' + xhash + ', ' + JSON.stringify(event));
            console.log("in onHash: hash = " + hash + ", selectedGatewayIdx = " + selectedGatewayIdx);
            if (isExpired) {
                modalTitle = contentIsExpiredTitle;
                modalMessage = contentIsExpiredMessage;
                messageContinuePage = "view";
                dispatch(reloadPage(''));
            } else {
                var url = gateways[selectedGatewayIdx].url_prefix + hash + "/";
                window.open(url, '_blank');
            }
        };

        const onShowUrls = () => (event) => {
            gatewaysModalTitle = gatewaysModalUrlsTitle;
            gatewaysModalMessage = gatewaysModalUrlsMessage;
            gatewaysModalList = gateways;
            gatewaysModalHash = hash;
            messageContinuePage = "view";
            dispatch(reloadPage(''));
        };

        const onDelete = () => (event) => {
            console.log('got onDelete: stamp = ' + stamp);
            dispatch(thunkDelContent({ hash: hash, stamp: stamp }));
        }

        const onAddCredits = (amount) => (event) => {
            // open a window to the hash content
            //const elem = document.getElementById('url-input');
            //console.log('in onHash! ' + xhash + ', ' + JSON.stringify(event));
            console.log('onAddCredits: amount = ' + amount);
            dispatch(setLongTaskDoneInfo(""));
            dispatch(thunkTopOffContent({ hash: hash, stamp: stamp, credits: amount }));
            topOffProgressDivClass = "visible";
        };

        let ShowOrDeleteButton;
        let expiresOrExpired;
        let expiresOrExpiredColor;
        if (!isExpired) {
            expiresOrExpired = "expires";
            expiresOrExpiredColor = "default";
            ShowOrDeleteButton = <Button variant="contained" className="showOrDeleteButton" disabled={false} onClick={ onShowUrls() } >Show Swarm URLS</Button>
        } else {
            expiresOrExpired = "expired";
            expiresOrExpiredColor = "error";
            ShowOrDeleteButton = <Button variant="contained" className="showOrDeleteButton" disabled={false} onClick={ onDelete() } >Delete from List</Button>
        }

        return(
                <ListItem ref={listItemRef} key={idx} sx={{ mt: 0, mb: 0, pt: 0, pb: 0 }} >
                  <div className="contentLineDiv">
                    <Accordion expanded={isExpanded} onChange={handlePanelChange(idx, hash)}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <div className="contentTitleExpirationAreaDiv">
                          <span className="contentTitleSpan">
                            <Typography paragraph={false} sx={{ ml: 0, fontSize: 12 }} >
                              { title }
                            </Typography>
                          </span>
                          <span className="contentExpirationSpan">
                            <Typography paragraph={false} color={expiresOrExpiredColor} sx={{ ml: 0, fontSize: 12 }} >
                              { expiresOrExpired }: { expiration }
                            </Typography>
                          </span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="contentDetailsAreaDiv">
                          <span className="hashSpan">
                            <button className="clickableHashLink button" type="button"
                                    onClick={ onHash() }>
                              <Tooltip placement="left" title={hash} arrow
                                       TransitionComponent={Zoom} TransitionProps={{ timeout: 600 }} >
                                <Typography paragraph={false} sx={{ ml: 0, fontSize: 10 }} >
                                  { shortHash }
                                </Typography>
                              </Tooltip>
                            </button>
                          </span>
                          <span className="showUrlsSpan">
                            {ShowOrDeleteButton}
                          </span>
                          <span className="add100CreditButtonSpan">
                            <Tooltip placement="top-end" title={add100ButtonTip}>
                              <div id="add100ButtonDiv" disabled={!add100ButtonEnable} >
                                <Button variant="contained" className="add100CreditsButton" disabled={!add100ButtonEnable} onClick={ onAddCredits(100) } >Add 100 Credits</Button>
                              </div>
                            </Tooltip>
                          </span>
                          <span className="add10CreditButtonSpan">
                            <Tooltip placement="top-end" title={add10ButtonTip}>
                              <div id="add10ButtonDiv" disabled={!add10ButtonEnable} >
                                <Button variant="contained" className="add10CreditsButton" disabled={!add10ButtonEnable} onClick={ onAddCredits(10) } >Add 10 Credits</Button>
                              </div>
                            </Tooltip>
                          </span>
                          <span className="add1CreditButtonSpan">
                            <Tooltip placement="left" title={add1ButtonTip}>
                              <div id="add1ButtonDiv" disabled={!add1ButtonEnable} >
                                <Button variant="contained" className="add1CreditButton" disabled={!add1ButtonEnable} onClick={ onAddCredits(1) } >Add 1 Credit</Button>
                              </div>
                            </Tooltip>
                          </span>
                        </div>

                      </AccordionDetails>
                    </Accordion>
                  </div>
                </ListItem>
        );
    };

    const onErrorClose = () => {
        console.log("ViewPage: got onErrorClose()");
        topOffProgressDivClass = "hidden";
        topOffMessageDivClass = "gone";
        dispatch(unInitUploadFeature());
        dispatch(unInitContentFeature());
        dispatch(setContentErrorStatus(""));
    };

    const onContinue = () => {
        console.log("ViewPage: got onContinue()");
        topOffProgressDivClass = "hidden";
        topOffMessageDivClass = "gone";
        initDone = false;
        dispatch(unInitUploadFeature());
        dispatch(unInitContentFeature());
    };

    const onMessageClose = () => {
        console.log("ViewPage: got onMessageClose()");
        modalMessage = "";
        gatewaysModalList = [];
        dispatch(setMode(messageContinuePage));
    };

    const onSortTitle = () => {
        console.log("ViewPage: got onSortTitle()");
        let sortTitleNotExp = contentData.sortTitleNotExp;
        let sortUpNotDown = contentData.sortUpNotDown;
        if (sortTitleNotExp) {
            sortUpNotDown = !sortUpNotDown;
        } else {
            sortTitleNotExp = true;
            sortUpNotDown = false;
        }
        dispatch(thunkSetSort({ sortTitleNotExp: sortTitleNotExp, sortUpNotDown: sortUpNotDown }));
    };
    const onSortExpiration = () => {
        console.log("ViewPage: got onSortExpiration()");
        let sortTitleNotExp = contentData.sortTitleNotExp;
        let sortUpNotDown = contentData.sortUpNotDown;
        if (!sortTitleNotExp) {
            sortUpNotDown = !sortUpNotDown;
        } else {
            sortTitleNotExp = false;
            sortUpNotDown = false;
        }
        dispatch(thunkSetSort({ sortTitleNotExp: sortTitleNotExp, sortUpNotDown: sortUpNotDown }));
    };
    const onGatewaySetting = () => {
        console.log("ViewPage: got onGatewaySetting()");
        gatewaysModalTitle = gatewaysModalSettingTitle;
        gatewaysModalMessage = gatewaysModalSettingMessage;
        gatewaysModalList = gateways;
        gatewaysModalHash = "";
        messageContinuePage = "view";
        dispatch(reloadPage(''));
    };
    const onSelectGateway = (idx) => {
        selectedGatewayIdx = idx;
        onMessageClose();
    };

    const SortTitleIcon = (props) => {
        if (!contentData.sortTitleNotExp) {
            return(<Icon path={SortNoneIcon} color="primary" size={1} />);
        } else if (contentData.sortUpNotDown) {
            return(<Icon path={SortUpIcon} color="primary" size={1} />);
        } else {
            return(<Icon path={SortDownIcon} color="primary" size={1} />);
        }
    }

    const SortExpIcon = (props) => {
        if (contentData.sortTitleNotExp) {
            return(<Icon path={SortNoneIcon} color="primary" size={1} />);
        } else if (contentData.sortUpNotDown) {
            return(<Icon path={SortUpIcon} color="primary" size={1} />);
        } else {
            return(<Icon path={SortDownIcon} color="primary" size={1} />);
        }
    }

    const TopOffMessageOrError = (props) => {
        if (!!uploadData.longTaskDoneInfo) {
            const expiration = new Date(Number(uploadData.longTaskDoneInfo) * 1000).toDateString();
            const hdr = (!!props?.noHeader) ? null : <tr><td colSpan="3"><span className="topOffExpirationMessage">Top-off postage complete:</span></td></tr>;
            return(
               <table>
                 <tbody>
                    {hdr}
                   <tr><td>&nbsp;</td><td><span className="topOffExpirationMessage">Expiration extended to:</span></td><td><span className="topOffExpirationMessage">{expiration}</span></td></tr>
                 </tbody>
               </table>
            );
        } else if ((uploadData.longTaskProgress == 0 || uploadData.longTaskProgress == 100) && uploadData.longTaskErrorStatus.length > 0) {
            return(
               <Typography color="error">{uploadData.longTaskErrorStatus}</Typography>
            );
        } else {
            return(null);
        }
    };

    return (
            <div id="rootGrid">
              <TxidProgressCard txid={activeTxid} clock={txClock} />
              <ErrorDialog message={errorMessage} closeAction={onErrorClose} severity={errorSeverity} />
              <MessageDialog message={modalMessage} closeAction={onMessageClose} title={modalTitle} />
              <GatewaysModal message={gatewaysModalMessage} title={gatewaysModalTitle}
                             gateways={gatewaysModalList} hash={gatewaysModalHash} selectedIdx={selectedGatewayIdx}
                             closeAction={onMessageClose} selectAction={onSelectGateway} />
              <TopMenuBar subtitle=" - View Content"/>
              <div id="pageDiv">
                <div id="contentAreaDiv">
                  <div id="waitIconDiv" className={waitIconDivClass}><WaitIcon/></div>
                  <ThemeProvider theme={CustomTheme}>
                    <Container fixed sx={{ bgcolor: 'primary.main', color: 'primary.contrast' }}>
                      <div id="viewOptionsDiv" className={viewOptionsDivClass}>
                        <span id="sortTitleButtonSpan">
                          <Button variant="text" id="sortTitleButton" disabled={false} onClick={onSortTitle}>
                            <SortTitleIcon/>
                          </Button>
                        </span>
                        <span id="gatewaySettingButtonSpan">
                          <Button variant="text" id="gatewaySettingButton" disabled={false} onClick={onGatewaySetting}>
                            <Icon path={GatewaySettingIcon} size={1} color="primary" />
                          </Button>
                        </span>
                        <span id="sortExpButtonSpan">
                          <Button variant="text" id="sortExpirationButton" disabled={false} onClick={onSortExpiration}>
                            <SortExpIcon/>
                          </Button>
                        </span>
                      </div>
                      <List id="contentList" style={{maxHeight: contentListMaxHeight + 'px', overflow: 'auto'}} onScroll={onScroll}  >
                        {content.map((c, i) => (
                                <ViewItem key={i} title={c.title} hash={c.hash} stamp={c.postageId} expiration={c.expiration} idx={i} />
                        ))}
                      </List>
                      <div id="topOffProgressDiv" className={topOffProgressDivClass}>
                        <Typography gutterBottom={false}
                                    variant="body2">{ uploadData.longTaskMilestone }
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                          <LinearProgressWithLabel value={uploadData.longTaskProgress} />
                        </Box>
                      </div>
                      <div id="topOffMessageDiv" className={topOffMessageDivClass}>
                        <TopOffMessageOrError/>
                        <Button id="continueButton" className={continueButtonClass} variant="contained" component="span" onClick={onContinue}>
                          Continue
                        </Button>
                      </div>
                    </Container>
                  </ThemeProvider>
                </div>
              </div>
            </div>
    );
}

export default ViewPage;
