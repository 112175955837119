/* ----------------------------------------------------------------------------------------------------- */
/* component shows the user's postage credit balance in a box                                            */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState }            from "react";
import { useDispatch, useSelector }   from "react-redux";
import PropTypes                      from 'prop-types';
import { connectDataSelector,
         thunkGetPostageCredit,
         thunkRefreshPostageCredit }  from "../features/connectFeature";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IconButton }                 from '@mui/material';
import Paper                          from '@mui/material/Paper';
import Typography                     from '@mui/material/Typography';
import CircularProgress               from '@mui/material/CircularProgress';
import RefreshIcon                    from '@mui/icons-material/Refresh';

import '../css/common.css';
const common    = require('../lib/common');
const ether     = require('../lib/ether');

let connectChangeMarkerPrev = -1;
let balanceBoxDivClass      = "hidden";
let refreshProgressClass    = "hidden";
let refreshButtonClass      = "hidden";
let postageCreditDsp        = "";

const PostageCreditBox = (props) => {

    const dispatch = useDispatch();
    const connectData = useSelector(connectDataSelector);

    const beenRendered = !!document.getElementById('rootGrid');
    if (beenRendered) {
        if (connectChangeMarkerPrev != connectData.changeMarker) {
            console.log("PostageCreditBox: change detected");
            connectChangeMarkerPrev  = connectData.changeMarker;
            if (!!connectData.network && !connectData.postageCredit) {
                console.log("PostageCreditBox: invoke thunk");
                thunkGetPostageCredit({ addr: connectData.address });
            }
            if (!!connectData.network) {
                balanceBoxDivClass = "visible";
                refreshButtonClass = (!!connectData.creditRefreshPending) ? "gone" : "visible";
                refreshProgressClass = (!!connectData.creditRefreshPending) ? "visible" : "gone";
            } else {
                refreshButtonClass = refreshProgressClass = balanceBoxDivClass = "hidden";
            }
            if (!!connectData.connectErrorStatus) {
                postageCreditDsp = "error";
            } else {
                postageCreditDsp = !!connectData.postageCredit ? connectData.postageCredit : "...";
                console.log("PostageCreditBox: postageCreditDsp = " + postageCreditDsp);
            }
        }
    }

    const onRefresh = () => {
        dispatch(thunkRefreshPostageCredit({ addr: connectData.address }));
    };

    return (
            <div id="postageCreditBoxDiv">
              <div id="postageCreditBoxWaitBoxDiv">
                <CircularProgress size="20px" color="secondary" thickness={7} sx={{ "padding": "0px", "margin": "auto"}} className={refreshProgressClass}/>
                <div className={refreshButtonClass}>
                  <IconButton id="postageCreditRefreshButton" aria-label="refresh" sx={{ "padding": "0px" }} onClick={onRefresh}>
                    <RefreshIcon />
                  </IconButton>
                </div>
              </div>
              <div id="postageCreditBoxBalanceBoxDiv" className={balanceBoxDivClass}>
                <Paper sx={{ "textAlign": "center",
                       "& .MuiDialog-paper": {borderRadius: "5px", "padding": "1em", "bgcolor": "#e0e0e0"} }} >
                  <table>
                    <tbody>
                      <tr><td><Typography sx={{ "textAlign": "left",  "fontSize": "0.8rem", "padding": "0px", "lineHeight": "12px" }}>Postage Credit:</Typography></td></tr>
                      <tr><td><Typography sx={{ "textAlign": "right", "fontSize": "0.8rem", "padding": "0px", "lineHeight": "12px" }}>{postageCreditDsp}</Typography></td></tr>
                    </tbody>
                  </table>
                </Paper>
              </div>
            </div>
    );
}

export default PostageCreditBox;
