/* ----------------------------------------------------------------------------------------------------- */
/* component shows the user's native balance (eth or matic) and dai balance in a box                     */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState }            from "react";
import { useSelector }                from "react-redux";
import PropTypes                      from 'prop-types';
import { connectDataSelector }        from "../features/connectFeature";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Paper                          from '@mui/material/Paper';
import Typography                     from '@mui/material/Typography';

import '../css/common.css';
const common    = require('../lib/common');
const ether     = require('../lib/ether');

let connectChangeMarkerPrev = -1;
let balanceBoxDivClass      = "hidden";

let nativeTokenBalance      = "0.000";
let nativeTokenName         = "ETHER";
let coinBalance             = "0.000";
let coinName                = "DAI";


const BalanceBox = (props) => {

    const connectData = useSelector(connectDataSelector);

    const beenRendered = !!document.getElementById('rootGrid');
    if (beenRendered) {
        if (connectChangeMarkerPrev != connectData.changeMarker) {
            console.log("BalanceBox: change detected");
            connectChangeMarkerPrev  = connectData.changeMarker;
            if (!!connectData.network) {
                nativeTokenName = ether.nativeToken;
                nativeTokenBalance = common.weiToDecimal(connectData.nativeBalanceWei, 3, 'ether');
                if (connectData.isDaiNotPyusd) {
                    coinName = "DAI";
                    coinBalance = common.weiToDecimal(connectData.daiBalanceWei, 3, 'ether');
                } else {
                    coinName = "PYUSD";
                    coinBalance = common.weiToDecimal(connectData.pyusdBalanceWei, 3, 'mwei');
                }
            }
            balanceBoxDivClass = (!!connectData.network) ? "visible" : "hidden";
        }
    }

    return (
            <div id="balanceBoxDiv" className={balanceBoxDivClass}>
              <Paper sx={{ "textAlign": "center",
                         "& .MuiDialog-paper": {borderRadius: "5px", "padding": "1em", "bgcolor": "#e0e0e0"} }} >
                <table>
                  <tbody>
                    <tr>
                      <td><Typography sx={{ "textAlign": "left",  "fontSize": "0.8rem", "padding": "0px", "lineHeight": "12px", "minWidth": "5em" }}>{nativeTokenName}:</Typography></td>
                      <td><Typography sx={{ "textAlign": "right", "fontSize": "0.8rem", "padding": "0px", "lineHeight": "12px", "minWidth": "4em" }}>{nativeTokenBalance}</Typography></td>
                    </tr>
                    <tr>
                      <td><Typography sx={{ "textAlign": "left",  "fontSize": "0.8rem", "padding": "0px", "lineHeight": "12px" }}>{coinName}:</Typography></td>
                      <td><Typography sx={{ "textAlign": "right", "fontSize": "0.8rem", "padding": "0px", "lineHeight": "12px" }}>{coinBalance}</Typography></td>
                    </tr>
                  </tbody>
                </table>
              </Paper>
            </div>
    );
}

export default BalanceBox;
