import { configureStore }  from "@reduxjs/toolkit";
import pageDataReducer     from "./features/pageFeature";
import connectDataReducer  from "./features/connectFeature";
import contentDataReducer  from "./features/contentFeature";
import purchaseDataReducer from "./features/purchaseFeature";
import uploadDataReducer   from "./features/uploadFeature";

export default configureStore({
  reducer: {
      pageData:     pageDataReducer,
      connectData:  connectDataReducer,
      contentData:  contentDataReducer,
      purchaseData: purchaseDataReducer,
      uploadData:   uploadDataReducer,
  },
});
