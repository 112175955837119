import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// needed for async when using bable
const regeneratorRuntime = require("regenerator-runtime");

export const pageSlice = createSlice({
    name: 'pageData',
    initialState: {
        changeMarker: 0,
        pageMode: 'landing',
        isSpinning: false,
    },
    reducers: {
        reloadPage: (state, action) => {
            ++state.changeMarker;
        },
        setMode: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.pageMode = action.payload;
            ++state.changeMarker;
            console.log('hey');
        },
    }
})

export const {
    reloadPage,
    setMode
} = pageSlice.actions;
export const pageDataSelector = state => state.pageData;
export default pageSlice.reducer;
