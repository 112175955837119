/* ----------------------------------------------------------------------------------------------------- */
/* this is just a linear progress control                                                                */
/* 0 <= value <= 100                                                                                     */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState }      from "react";
import PropTypes                from 'prop-types';
import LinearProgress           from '@mui/material/LinearProgress';

/* ----------------------------------------------------------------------------------------------------- */
/* mui container and friends                                                                             */
/* ----------------------------------------------------------------------------------------------------- */
import Box                      from '@mui/material/Box';
import Typography               from '@mui/material/Typography';

function LinearProgressWithLabel(props) {
    return (
	    <Box sx={{ display: 'flex', alignItems: 'center' }}>
	    <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" color="info" {...props} />
	    </Box>
	    <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
	    </Box>
	    </Box>
    );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default LinearProgressWithLabel;
