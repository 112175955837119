/* ----------------------------------------------------------------------------------------------------- */
/* this is the main entrypoint for the Easy Swarm Gateway App                                          */
/* it's main functionality is to:                                                                        */
/*  a. interpret any url parameters, and select the current pageMode based thereon.                      */
/*     if there is no url parameter selecting a page, then set the pageMode to 'landing'.                */
/*  b. call up the page associated with the current pageMode.                                            */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState }          from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageDataSelector,
         setMode }                  from "./features/pageFeature";
import LandingPage                  from './components/LandingPage'
import PurchasePage                 from './components/PurchasePage'
import UploadPage                   from './components/UploadPage'
import ViewPage                     from './components/ViewPage'
import './css/common.css';
import './css/landingPage.css';
const common = require('./lib/common');


function EasySwarmGateway() {
  const pageData = useSelector(pageDataSelector);
  const dispatch = useDispatch();

  const beenRendered = !!document.getElementById('rootGrid');

  //Sample address: http://localhost:3000/?page=eat&content=0x12345
  const queryParams = new URLSearchParams(window.location.search);
  const page = queryParams.get('page');
  if (!!page) {
    if (page == 'purchase' || page == 'upload')
      dispatch(setMode(page));
    else
      dispatch(setMode('landing'));
  } else if (!pageData.pageMode) {
    dispatch(setMode('landing'));
  }


  const PageSelect = () => {
    console.log('pageData.pageMode = ' + pageData.pageMode);
    if (pageData.pageMode == 'purchase') {
      return <PurchasePage />;
    } else if (pageData.pageMode == 'upload') {
      return <UploadPage />;
    } else if (pageData.pageMode == 'view') {
      return <ViewPage />;
    } else {
      return <LandingPage />;
    }
  };

  return (
    <PageSelect />
  );
}

export default EasySwarmGateway;
