/* ----------------------------------------------------------------------------------------------------- */
/* this is the upload page for the Easy Swarm Gateway App                                                */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState, useEffect  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageDataSelector,
         setMode }                  from '../features/pageFeature';
import { uploadDataSelector,
         setUploadFile,
         setLongTaskDoneInfo,
         unInitUploadFeature,
         thunkInitUploadFeature,
         thunkExecuteUpload }       from "../features/uploadFeature";
import { contentDataSelector,
         unInitContentFeature }     from "../features/contentFeature";
import { connectDataSelector}       from "../features/connectFeature";
import TopMenuBar                   from './TopMenuBar';
import CustomTheme                  from './CustomTheme';
import TxidProgressCard             from './TxidProgressCard';
import ErrorDialog                  from './ErrorDialog';
import MessageDialog                from './MessageDialog';
import LinearProgressWithLabel      from './LinearProgressWithLabel';

import '../css/common.css';
import '../css/uploadPage.css';

const common = require('../lib/common');

/* ----------------------------------------------------------------------------------------------------- */
/* mui container and friends                                                                             */
/* ----------------------------------------------------------------------------------------------------- */
import Box                      from '@mui/material/Box';
import Chip                     from '@mui/material/Chip';
import Container                from '@mui/material/Container';
import Grid                     from '@mui/material/Grid';
import List                     from '@mui/material/List';
import ListItem                 from '@mui/material/ListItem';
import ListItemText             from '@mui/material/ListItemText';
import TextField                from '@mui/material/TextField';
import Typography               from '@mui/material/Typography';
import { ThemeProvider }        from '@mui/material/styles';
import { positions }            from '@mui/system';

/* ----------------------------------------------------------------------------------------------------- */
/* mui forms                                                                                             */
/* ----------------------------------------------------------------------------------------------------- */
import Button                   from '@mui/material/Button';
import Tooltip                  from '@mui/material/Tooltip';
import Input                    from '@mui/material/Input';
import FilledInput              from '@mui/material/FilledInput';
import OutlinedInput            from '@mui/material/OutlinedInput';
import InputLabel               from '@mui/material/InputLabel';
import InputAdornment           from '@mui/material/InputAdornment';
import InputUnstyled            from '@mui/base/InputUnstyled';
import Switch                   from '@mui/material/Switch';
import FormHelperText           from '@mui/material/FormHelperText';
import FormControlLabel         from '@mui/material/FormControlLabel';
import FormControl              from '@mui/material/FormControl';
import FormLabel                from '@mui/material/FormLabel';
import TextareaAutosize         from '@mui/material/TextareaAutosize';

let pageChangeMarkerPrev     = -1;
let uploadChangeMarkerPrev   = -1;
let connectChangeMarkerPrev  = -1;
let swarmHashMessageDivClass = "gone";
let uploadProgressDivClass   = "hidden";
let continueButtonClass      = "gone";
let uploadSelectButtonEnable =  true;
let uploadSelectButtonTip    = "";
let activeTxid               = "";
let txClock                  = "";
let errorMessage             = "";
let errorSeverity            = "success";

function UploadPage() {
    const pageData = useSelector(pageDataSelector);
    const uploadData = useSelector(uploadDataSelector);
    const contentData = useSelector(contentDataSelector);
    const connectData = useSelector(connectDataSelector);
    const dispatch = useDispatch();

    const beenRendered = !!document.getElementById('rootGrid');

    // if swarm hasn't been initialized, and hasn't failed initialization, then initialize now
    useEffect(() => {
        if (!uploadData.beenInitialized) {
            dispatch(setUploadFile({ name: "", data: "" }));
            dispatch(setLongTaskDoneInfo( { doneInfo: "", milestone: "" } ));
            if (!uploadData.longTaskErrorStatus)
                dispatch(thunkInitUploadFeature());
            else
                console.log("UploadPage: other error prevents initialization! (" + uploadData.longTaskErrorStatus + ")");
        }
    }, [ uploadData.changeMarker ] );


    if (beenRendered) {
        console.log("pageMode = " + pageData.pageMode);
        if (pageData.pageMode == 'upload' && ((pageChangeMarkerPrev    != pageData.changeMarker    ) ||
                                              (uploadChangeMarkerPrev  != uploadData.changeMarker  ) ||
                                              (connectChangeMarkerPrev  != connectData.changeMarker)) ) {
            console.log("UploadPage: change detected; longTaskProgress = "   + uploadData.longTaskProgress);
            console.log("UploadPage: change detected; longTaskErrorStatus = " + uploadData.longTaskErrorStatus);
            console.log("UploadPage: change detected; beenInitialized = " + uploadData.beenInitialized);
            pageChangeMarkerPrev    = pageData.changeMarker;
            uploadChangeMarkerPrev  = uploadData.changeMarker;
            connectChangeMarkerPrev = connectData.changeMarker;

            if (false /*!!(errorMessage = connectData.error)*/) {
                errorSeverity = "error";
                //console.log("UploadPage: connect error prevents initialization! " + connectData.error);
            } else if (!uploadData.beenInitialized) {
                uploadSelectButtonEnable = false;
                uploadProgressDivClass = "hidden";
                swarmHashMessageDivClass = "gone";
            } else {
                const swarmHashOrError = ((uploadData.longTaskProgress == 0          || uploadData.longTaskProgress == 100  ) &&
                                          ((!!uploadData.longTaskErrorStatus && uploadData.longTaskErrorStatus.length > 0) ||
                                           (!!uploadData.longTaskDoneInfo    && uploadData.longTaskDoneInfo.length    > 0)) );
                console.log("UploadPage: swarmHashOrError = "   + swarmHashOrError);
                if (swarmHashOrError) {
                    swarmHashMessageDivClass = "visible";
                    if (!!uploadData.longTaskErrorStatus) {
                        errorMessage = uploadData.longTaskErrorStatus;
                        errorSeverity = "error";
                        continueButtonClass = "gone";
                    } else {
                        errorMessage = "";
                        continueButtonClass = "visible";
                    }

                } else {
                    errorMessage = "";
                    continueButtonClass = "gone";
                    swarmHashMessageDivClass = "gone";
                    if (!connectData.network) {
                        uploadSelectButtonTip = "You need to connect your wallet";
                        uploadSelectButtonEnable = false;
                    } else if (!!connectData.connectErrorStatus) {
                        uploadSelectButtonTip = "Unable to communicate with ez-swarm server";
                        uploadSelectButtonEnable = false;
                    } else if (!!connectData.postageCredit && connectData.postageCredit < 1.0) {
                        uploadSelectButtonTip = "Insufficient postage credit";
                        uploadSelectButtonEnable = false;
                    } else {
                        uploadSelectButtonTip = "Click to select a file to upload to SWARM";
                        uploadSelectButtonEnable = true;
                    }
                    uploadProgressDivClass = (uploadData.longTaskProgress != 0 && uploadData.longTaskProgress != 100)
                        ? "visible"
                        : "hidden";
                }
                txClock = ""; //bccData.activeTxProgress.toString();
                console.log("UploadPage: uploadSelectButtonEnable = "   + uploadSelectButtonEnable);
            }
        }
    }

    const onErrorClose = () => {
        console.log("UploadPage: got onErrorClose()");
        // to re-arm the uploadSelectInput selector, even if the user selects the same file
        uploadSelectInput.value = null;
        dispatch(unInitUploadFeature());
    };

    const onContinue = () => {
        console.log("UploadPage: got onContinue()");
        dispatch(unInitUploadFeature());
        dispatch(unInitContentFeature());
    };

    //
    // select a local file to upload
    //
    const onUploadSelect = () => {
        console.log("uploadSelectButton.files = " + uploadSelectInput.files);
        uploadProgressDivClass = "hidden";
        if (uploadSelectInput.files && uploadSelectInput.files[0]) {
            console.log("uploadSelectInput: got " + uploadSelectInput.files[0].name + ", " + uploadSelectInput.value);
            const reader = new FileReader();
            reader.onload = (e) => {
                //console.log('uploadSelectButton: e.target.result = ' + e.target.result);
                //eg. e.target.result = data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAACx1BMV...
                const imageKb = parseInt(e.target.result.length / 1024);
                console.log('image length = ' + e.target.result.length + ', ' + imageKb + "KB");
                const fileName = uploadSelectInput.files[0].name;
                dispatch(setUploadFile({ name: fileName, data: e.target.result }));
                dispatch(setLongTaskDoneInfo(""));
                const contentTitle = (fileName.indexOf(".") >= 0) ? fileName.substring(0, fileName.lastIndexOf(".")) : fileName;
                common.setButtonState("uploadExecuteButton", "Enabled");
            };
            reader.readAsDataURL(uploadSelectInput.files[0]);
        } else {
            /*
            dispatch(setUploadFile({ name: "", data: "" }));
            */
            dispatch(setUploadFile({ name: "" }));
            dispatch(setLongTaskDoneInfo(""));
            dispatch(setContentTitle(""));
            dispatch(setContentComment(""));
            common.setButtonState("uploadExecuteButton", "Disabled");
        }
    };

    //
    // upload selected file to swarm
    //
    const onUploadExecute = () => {
        //console.log("onUploadExecute: file = " + uploadFile.uploadFileName);
        //console.log("onUploadExecute: data = " + uploadFile.uploadFileData);
        //const was = common.setLoadingIcon('start');
        uploadProgressDivClass = "visible";
        dispatch(thunkExecuteUpload());
    };
    let uploadExecuteButton;
    if (!!uploadData.uploadFileName && !uploadData.longTaskDoneInfo) {
        uploadExecuteButton = <Button variant="contained" id="uploadExecuteButton" disabled={false} onClick={onUploadExecute}>Upload File</Button>
    } else {
        uploadExecuteButton = <Button variant="contained" id="uploadExecuteButton" disabled={true} onClick={onUploadExecute}>Upload File</Button>
    }

    const SwarmHashMessageOrError = (props) => {
        if (!!uploadData.longTaskDoneInfo) {
            const hdr = (!!props?.noHeader) ? null : <tr><td colSpan="3"><span className="swarmHashMessage">Swarm upload complete:</span></td></tr>;
            return(
               <table>
                 <tbody>
                    {hdr}
                   <tr><td>&nbsp;</td><td><span className="swarmHashMessage">file:</span></td><td><span className="swarmHashMessage">{uploadData.uploadFileName}</span></td></tr>
                   <tr><td>&nbsp;</td><td><span className="swarmHashMessage">hash:</span></td><td><span className="swarmHashMessage">{uploadData.longTaskDoneInfo}</span></td></tr>
                 </tbody>
               </table>
            );
        } else if ((uploadData.longTaskProgress == 0 || uploadData.longTaskProgress == 100) && uploadData.longTaskErrorStatus.length > 0) {
            return(
               <Typography color="error">{uploadData.longTaskErrorStatus}</Typography>
            );
        } else {
            return(null);
        }
    };

    return (
            <div id="rootGrid">
              <TxidProgressCard txid={activeTxid} clock={txClock} />
              <ErrorDialog message={errorMessage} closeAction={onErrorClose} severity={errorSeverity} />
              <TopMenuBar subtitle=" - Post Content"/>
              <div id="pageDiv">
                <div id="contentAreaDiv">
                  <ThemeProvider theme={CustomTheme}>
                    <Container fixed sx={{ bgcolor: 'primary.main', color: 'primary.contrast' }}>
                      <List>
                        <ListItem key={0}>
                          <Typography gutterBottom={false}
                                      variant="h6">Upload Content</Typography>
                        </ListItem>
                        <ListItem key={1}>
                          <Typography gutterBottom={true}
                                      variant="body1">Select a file from your compter hard-drive, and upload it. This will break the content into separate, encrypted pieces
                            (or "chunks"), and distribute the chunks to thousands of servers around the world. Each server does not know what content it is serving. This
                            un-censorable content distribution system is called SWARM</Typography>
                        </ListItem>
                        <ListItem key={2}>
                          <Typography gutterBottom={true}
                                      variant="body1">At this time, EZ-Swarm only allows you to upload content that is twenty-four megabytes or less in size (24MB). Also
                            note that uploading content will cost you one (1) Postage Credit. Once the content is uploaded to SWARM, it will have an initial lifespan of
                            approximately sixty days. If you want to extend its lifespan on SWARM, then after it has been uploaded you can "top-off" its postage (on the
                            View-My-Content page). For every Postage Credit that you use, the lifespan of the content will be extended by approximatly ninety days.</Typography>
                        </ListItem>
                      </List>
                      <div id="uploadAreaDiv">
                        <div id="uploadSelectButtonDiv">
                          <Tooltip placement="top-end" title={uploadSelectButtonTip}>
                            <label htmlFor="uploadSelectInput">
                              <Input disableUnderline={true}
                                     id="uploadSelectInput" type="file" accept="video/mp4" onChange={onUploadSelect}></Input>
                              <Button id="uploadSelectButton" disabled={!uploadSelectButtonEnable} variant="contained" component="span" name="filename">
                                Select File
                              </Button>
                              {' ' + uploadData.uploadFileName}
                            </label>
                          </Tooltip>
                        </div>
                        <div id="uploadExecuteButtonDiv">
                          {uploadExecuteButton}
                        </div>
                      </div>
                      <div id="uploadProgressDiv" className={uploadProgressDivClass}>
                        <Typography gutterBottom={false}
                                    variant="body2">{ uploadData.longTaskMilestone }
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                          <LinearProgressWithLabel value={uploadData.longTaskProgress} />
                        </Box>
                      </div>
                      <div id="swarmHashMessageDiv" className={swarmHashMessageDivClass}>
                        <SwarmHashMessageOrError/>
                        <Button id="continueButton" className={continueButtonClass} variant="contained" component="span" onClick={onContinue}>
                              Continue
                        </Button>
                      </div>
                    </Container>
                  </ThemeProvider>
                </div>
              </div>
            </div>
    );
}

export default UploadPage;
