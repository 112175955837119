import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios                             from "axios";
import { ezProxyUrlPrefix }              from "./connectFeature";


// needed for async when using bable
const regeneratorRuntime = require("regenerator-runtime");
const BN = require("bn.js");

const common = require('../lib/common');
const ether = require('../lib/ether');
const to = require('../lib/to');

// note thunkAPI = { dispatch, getState, extra, rejectWithValue }
// where extra is the "extra argument" given to the thunk middleware on setup, if available
export const thunkInitPurchaseFeature = createAsyncThunk(
    'connect/thunkInitPurchaseFeature',
    async (arg, thunkAPI) => {
        console.log('thunkInitPurchaseFeature: at least im in the thunk');
        return new Promise((resolve, reject) => {
            try {
                thunkAPI.dispatch(thunkGetPurcaseAddr());
                resolve('okey dokey');
            } catch (err) {
                console.error('thunkInitPurchase cought err = ' + err);
            }
        });
    }
);


// retrieve the ez-swarm postage purchase payment address from the ez-proxy
export const thunkGetPurcaseAddr = createAsyncThunk(
    'connect/thunkGetPurcaseAddr',
    async (arg, thunkAPI) => {
        console.log('thunkGetPurcaseAddr: at least im in the thunk');
        const state = thunkAPI.getState();
        let postageCredit = "0.00";
        return new Promise((resolve, reject) => {
            try {
                console.log('thunkGetPurcaseAddr: in the promise');
                console.log("state.connectData.ezProxyUrlPrefix = " + state.connectData.ezProxyUrlPrefix);
                axios.get(ezProxyUrlPrefix + "server-info")
                    .then((rsp) => {
                        const info = rsp.data;
                        const err = common.arrayify(info).length == 0 ? "No response from server" : info.err;
                        if (err) {
                            throw err;
                        } else {
                            const serverInfo = info.serverInfo;
                            console.log('thunkGetPurcaseAddr: serverinfo = ' + JSON.stringify(serverInfo));
                            thunkAPI.dispatch(setPurchaseAddr(serverInfo));
                            resolve('okey dokey');
                        }
                    })
                    .catch(err => {
                        console.error('thunkGetPurcaseAddr err = ' + err);
                        thunkAPI.dispatch(setPurchaseError(err.toString()));
                        thunkAPI.rejectWithValue(err);
                    });
            } catch (err) {
                console.error('thunkGetPurcaseAddr cought err = ' + err);
                thunkAPI.dispatch(setPurchaseError(err.toString()));
                thunkAPI.rejectWithValue(err);
            }
        });
    }
);

//
// progress indicator is active during blockchain transactions
//
let activeTxProgressTimer = null;
async function updateActiveTxProgress(dispatch, getState) {
    let newProgress = 0;
    const state = getState();
    if (state.purchaseData.activeTxProgress == 0 || state.purchaseData.activeTxProgress == 100) {
        activeTxProgressTimer = null;
        console.log('updateActiveTxProgress: in timer; newProgress = ' + newProgress + '; all done');
    } else {
        newProgress = state.purchaseData.activeTxProgress + 1
        if (newProgress >= 100)
            newProgress = 1;
        console.log('updateActiveTxProgress: in timer; newProgress = ' + newProgress);
        dispatch(setActiveTxProgress(newProgress));
        activeTxProgressTimer = setTimeout(function() {
            updateActiveTxProgress(dispatch, getState);
        }, 1000);
    }
}

//
// convert an ether.send event emitter to a promise, with periodic calls to updateActiveTxProgress
//
const writeTxEventToPromise = (thunkAPI, writeTxFcn) => {
    return new Promise((resolve, reject) => {
        try {
            writeTxFcn()
                .on('transactionHash', function(txid) {
                    console.log('writeTxEventToPromise: txid = ' + txid);
                    thunkAPI.dispatch(setActiveTxid(txid));
                    activeTxProgressTimer = setTimeout(function() {
                        updateActiveTxProgress(thunkAPI.dispatch, thunkAPI.getState);
                    }, 1000);
                })
                .on('confirmation', function(confirmationNumber, receipt) {
                    // this fires up to 12 times
                    if (confirmationNumber == 0) {
                        // don't fire if the status is false (failed transaction)
                        if (!!receipt.status) {
                            const txid = receipt.transactionHash;
                            thunkAPI.dispatch(setActiveTxid(""));
                            console.log('writeTxEventToPromise: confirmation = ' + confirmationNumber + ', receipt = ' + receipt);
                            resolve(txid);
                        }
                    }
                })
                .on('error', function(err, receipt) {
                    const error = !!err.message ? err.message : err.toString();
                    console.log('writeTxEventToPromise: error event = ' + error + ', typeof(err) = ' + typeof(error));
                    reject(error);
                });
        } catch (err) {
            console.log('writeTxEventToPromise: cought event = ' + err + ', typeof(err) = ' + typeof(err));
            reject(err.toString());
        }
    });
};

//
// send funds to the ez-swarm purchase address
// executes an approve transaction if needed
// the progress indicator is active during blockchain transactions
//
export const thunkDoPurchase = createAsyncThunk(
    'connect/thunkDoPurchase',
    async (arg, thunkAPI) => {
        console.log('thunkDoPurchase: at least im in the thunk');
        const state = thunkAPI.getState();
        const sendAmount = arg.sendAmount;
        const paymentTokenAddr = state.connectData.isDaiNotPyusd ? ether.DAITokenAddr : ether.PYUSDTokenAddr;
        // PYUSD only has 6 decimals
        const paymentTokenUnits = state.connectData.isDaiNotPyusd ? 'ether' : 'mwei';
        console.log('thunkDoPurchase: sendAmount = ' + sendAmount);
        const sendAmountWeiBN = common.decimalToWeiBN(sendAmount.toString(), paymentTokenUnits);
        console.log('thunkDoPurchase: sendAmountWeiBN = ' + sendAmountWeiBN.toString());
        if (!state.purchaseData.purchaseAddr)
            throw "don't have puchaseAddr";
        return new Promise((resolve, reject) => {
            console.log('thunkDoPurchase: in promise');
            console.log('thunkDoPurchase: calling send fcn');
            console.log('thunkDoPurchase: purchaseAddr = ' + state.purchaseData.purchaseAddr);
            console.log('thunkDoPurchase: paymentTokenAddr = ' + paymentTokenAddr);
            console.log('thunkDoPurchase: sendAmountWeiBN = ' + sendAmountWeiBN.toString());
            const sendFcn = () => {
                return ether.ERC20transfer(paymentTokenAddr, state.purchaseData.purchaseAddr, sendAmountWeiBN);
            }
            writeTxEventToPromise(thunkAPI, sendFcn)
                .then(txid => {
                    console.log('thunkDoPurchase: send txid = ' + txid);
                    thunkAPI.dispatch(setPurchaseSuccess("Purchase completed successfully"));
                    resolve('okey dokey');
                })
                .catch(err => {
                    console.error('thunkDoPurchase err = ' + err);
                    thunkAPI.dispatch(setPurchaseError(err.toString()));
                    thunkAPI.dispatch(setActiveTxid(""));
                    thunkAPI.rejectWithValue(err);
                });
        });
    }
);


export const purchaseSlice = createSlice({
    name: 'purchaseData',
    initialState: {
        changeMarker:          0,
        beenInitialized:       false,
        purchaseAddr:          "",
        purchaseSuccessStatus: "",
        purchaseErrorStatus:   "",
        activeTxProgress:      0,
        activeTxid:            "",
    },
    reducers: {
        unInitPurchaseFeature: (state, action) => {
            state.beenInitialized = false;
            state.purchaseSuccessStatus = "";
            state.purchaseErrorStatus = "";
            state.purchaseAddr = "";
            ++state.changeMarker;
        },
        setPurchaseAddr: (state, action) => {
            const changed = (state.purchaseAddr != action.payload.purchaseAddr);
            state.purchaseAddr = action.payload.purchaseAddr;
            state.beenInitialized = true;
            if (changed)
                ++state.changeMarker;
        },
        setPurchaseSuccess: (state, action) => {
            state.purchaseSuccessStatus = action.payload;
            ++state.changeMarker;
        },
        setPurchaseError: (state, action) => {
            state.purchaseErrorStatus = action.payload;
            ++state.changeMarker;
        },
        setPurchaseProgress: (state, action) => {
            state.purchaseProgress = action.payload;
            ++state.changeMarker;
        },
        setActiveTxProgress: (state, action) => {
            state.activeTxProgress = action.payload;
            if (state.activeTxProgress == 0 || state.activeTxProgress == 100)
                state.activeTxid = "";
            ++state.changeMarker;
        },
        setActiveTxid: (state, action) => {
            state.activeTxid = action.payload;
            state.activeTxProgress = !!state.activeTxid ? 1 : 100;
            ++state.changeMarker;
        },
    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        // thunks will have X.pending, X.fulfilled, X.rejected
        [thunkInitPurchaseFeature.fulfilled]: (state, action) => { state.beenInitialized = true; ++state.changeMarker; },
    },
})

export const {
    unInitPurchaseFeature,
    setPurchaseSuccess,
    setPurchaseError,
    setPurchaseAddr,
    setActiveTxid,
    setActiveTxProgress,
} = purchaseSlice.actions;

export const purchaseDataSelector = state => state.purchaseData;
export default purchaseSlice.reducer;
