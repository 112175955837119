/* ----------------------------------------------------------------------------------------------------- */
/* component shows the status/timer for a pending transaction                                            */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState }            from "react";
import PropTypes                      from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography                     from '@mui/material/Typography';
import Card                           from '@mui/material/Card';
import CardContent                    from '@mui/material/CardContent';
import CardActions                    from '@mui/material/CardActions';
import Button                         from '@mui/material/Button';
import Tooltip                        from '@mui/material/Tooltip';
import Box                            from '@mui/material/Box';
import { styled }                     from '@mui/material/styles';

const TxidButton = styled(Button)({
  textTransform: 'none',
});

import '../css/common.css';
const common = require('../lib/common');
const ether  = require('../lib/ether');

const theme = createTheme({
    palette: {
	neutral: {
	    main: '#64748B',
	    contrastText: '#fff',
	},
    },
});

function TxidProgressCard(props) {
    const clock = props.clock;
    const txid = "txid: " + props.txid;
    const txStatusUrl = ether.txStatusUrlPrefix + props.txid;
    const shortTxid = common.abbreviateAddrForEns(txid, '', 16, 0, 64);
    const txidProgressCardDivClass = !!props.txid ? "visible" : "hidden";
    const toolTipText = txid;

    return (
	    <div id="txidProgressCardDiv" className={txidProgressCardDivClass} >
              <ThemeProvider theme={theme}>
		<Tooltip title={toolTipText}>
                  <Card sx={{ height: 100, bgcolor: 'primary.main', color: 'primary.contrast' }}>
		    <CardActions>
		      <TxidButton variant="contained" color="neutral" id="txidViewButton" href={txStatusUrl} target="_blank" rel="noreferrer">
			{shortTxid}
		      </TxidButton>
		    </CardActions>
		    <CardContent sx={{ margin: 0 }} >
		      <Typography sx={{ fontSize: 14 }} gutterBottom>
			{clock}
		      </Typography>
		    </CardContent>
		  </Card>
		</Tooltip>
	      </ThemeProvider>
	    </div>
    );
}

TxidProgressCard.propTypes = {
    txid:  PropTypes.string.isRequired,
    clock: PropTypes.string.isRequired,
};

export default TxidProgressCard;
