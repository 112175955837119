/* ----------------------------------------------------------------------------------------------------- */
/* this is the landing page for the EZ-Swarm Gateway App                                                 */
/* it's main functionality is to provide links to other pages                                            */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState }          from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageDataSelector,
         setMode }                  from '../features/pageFeature';

import TopMenuBar                   from './TopMenuBar';
import CustomTheme                  from './CustomTheme';
import ErrorDialog                  from './ErrorDialog';

import '../css/common.css';
import '../css/landingPage.css';

const common = require('../lib/common');

/* ----------------------------------------------------------------------------------------------------- */
/* mui container and friends                                                                             */
/* ----------------------------------------------------------------------------------------------------- */
import Box                      from '@mui/material/Box';
import Container                from '@mui/material/Container';
import Typography               from '@mui/material/Typography';
import List                     from '@mui/material/List';
import ListItem                 from '@mui/material/ListItem';
import ListItemText             from '@mui/material/ListItemText';
import TextField                from '@mui/material/TextField';
import { ThemeProvider }        from '@mui/material/styles';
import Button                   from '@mui/material/Button';
import Tooltip                  from '@mui/material/Tooltip';


const blathers = [
    "What is SWARM?",
        "Swarm is a peer-to-peer network of nodes that collectively provide a decentralised "    +
        "storage and communication service. This system is economically self-sustaining due "    +
        "to a built-in incentive system which is enforced through smart contracts on the "       +
        "Ethereum blockchain and powered by the BZZ token.",

    "Paying for storage",
        "When you post data on the SWARM network, it is actually broken into many encrypted "    +
        "pieces, which are distributed amongst the nodes of the network. Breaking up files  "    +
        "across independent nodes means that there is no practical way to censor content on "    +
        "the SWARM. It also means that to post on the SWARM you've got to pay those "            +
        "independant nodes for their storage space. In the SWARM network nodes are paid with "   +
        "postage that can be purchased on the xDAI/Gnosis network using BZZ tokens.",

    "The easier way",
        "EZ-SWARM Gateway is a gateway to the SWARM network that makes it possible to post "    +
        "content to the SWARM network, but pay with more common tokens and/or currencies, such " +
        "as DAI or PYUSD.",
];

let pageChangeMarkerPrev    = -1;
let connectChangeMarkerPrev = -1;
let errorMessage            = "";

function LandingPage() {
    const pageData = useSelector(pageDataSelector);
    const dispatch = useDispatch();

    const beenRendered = !!document.getElementById('rootGrid');
    if (beenRendered) {
        console.log("pageMode = " + pageData.pageMode);
        if (pageData.pageMode == 'landing' && ((pageChangeMarkerPrev != pageData.changeMarker   )) ) {
            console.log("LandingPage: change detected");
            pageChangeMarkerPrev = pageData.changeMarker;
            errorMessage = "";
            console.log("LandingPage: errorMessage = " + errorMessage);
        }
    }

    const onErrorClose = () => {
        console.log("LandingPage: got onErrorClose()");
        dispatch(setConnectionError(''));
        dispatch(unInitConnection());
    };

    return (
            <div id="rootGrid">
              <ErrorDialog message={errorMessage} closeAction={onErrorClose} />
              <TopMenuBar/>
              <div id="pageDiv">
                <div id="landingContentDiv">
                  <ThemeProvider theme={CustomTheme}>
                    <Container fixed sx={{ bgcolor: 'primary.main', color: 'primary.contrast' }}>
                      <List>
                        {blathers.map((b, i) => (
                        <ListItem key={i}>
                                <Typography
                                 variant={(i & 1)?"body1" : "h6"}
                                 gutterBottom={(i & 1)?true:false}>{b}
                                </Typography>
                        </ListItem>
                        ))}
                      </List>
                    </Container>
                  </ThemeProvider>
                </div>
              </div>
            </div>
    );
}

export default LandingPage;
