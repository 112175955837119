/* ----------------------------------------------------------------------------------------------------- */
/* component shows a modal with a list of Swarm gateways                                                 */
/* optionally allowa the user to select one.                                                             */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState }            from "react";
import PropTypes                      from 'prop-types';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dialog                         from '@mui/material/Dialog';
import DialogTitle                    from '@mui/material/DialogTitle';
import DialogContent                  from '@mui/material/DialogContent';
import DialogContentText              from '@mui/material/DialogContentText';
import Typography                     from '@mui/material/Typography';
import List                           from '@mui/material/List';
import ListItem                       from '@mui/material/ListItem';
import ListItemText                   from '@mui/material/ListItemText';
import TextField                      from '@mui/material/TextField';
import Tooltip                        from '@mui/material/Tooltip';
import Zoom                           from '@mui/material/Zoom';
import Link                           from '@mui/material/Link';

import Radio                          from '@mui/material/Radio';
import RadioGroup                     from '@mui/material/RadioGroup';
import FormControlLabel               from '@mui/material/FormControlLabel';
import FormControl                    from '@mui/material/FormControl';
import FormLabel                      from '@mui/material/FormLabel';
import Snackbar                       from '@mui/material/Snackbar';
import Alert                          from '@mui/material/Alert';

import '../css/common.css';
import '../css/gatewaysModal.css';

const common = require('../lib/common');

let showCopySnackbar = false;
const rawHashCopyTip = "This is the raw Swarm hash for this content. Click to copy the hash. You can then paste this hash " +
                       "into a public Swarm gateway, or your own Swarm desktop node.";


// props.severity: error | warning | info | success
// props.closeAction: callback when user closes the dialog. must at least clear the message.
const GatewaysModal = (props) => {
    const title = props.title;
    const message = props.message;
    const gateways = props.gateways;
    const hash = props.hash
    const shortHash = common.abbreviateAddrForEns(hash, "", 30, 0, 64);
    const closeAction = props.closeAction;
    const selectAction = props.selectAction
    const selectedIdx = props.selectedIdx;
    const isOpen = !!props.gateways.length ? true : false;

    console.log("GatewaysModal: selectedIdx = " + selectedIdx);

    const [showCopySnackbar, setShowCopySnackbar] = React.useState(false);

    const onClose = () => {
        console.log("GatewaysModal: got onClose()");
        if (!!closeAction) {
            closeAction();
        }
    };
    const onSelect = (event) => {
        console.log("GatewaysModal: event.target = " + event.target);
        console.log("GatewaysModal: got onSelect(" + event.target.value + ")");
        console.log("GatewaysModal: got onSelect(" + event.currentTarget.value + ")");
        if (!!selectAction) {
            selectAction(event.target.value);
        }
    };
    const onCopy = () => (event) => {
        var textarea = document.createElement('textarea');
        textarea.textContent = hash;
        document.body.appendChild(textarea);
        var selection = document.getSelection();
        var range = document.createRange();
        range.selectNode(textarea);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        selection.removeAllRanges();
        document.body.removeChild(textarea);
        setShowCopySnackbar(true);
    };
    const closeCopySnackbar = () => {
        console.log("closeCopySnackbar: got event " + JSON.stringify(event));
        setShowCopySnackbar(false);
    };

    /* ----------------------------------------------------------------------------------------------------- */
    // list item for a single piece of content
    // props: key, hash, idx, title
    /* ----------------------------------------------------------------------------------------------------- */
    const GatewayEntry = (props) => {
        const description = props.description;
        const url_prefix = props.url_prefix;
        const idx = props.idx;

        if (!!hash && !!url_prefix) {
            // here for normal links via public gateways. show the description folloed by the link
            return (<ListItem key={idx} sx={{ mt: 0, mb: 0, pt: 0, pb: 0, "paddingLeft": "0px" }} >
                      <span className="gatewayDescSpan">
                        <Typography paragraph={true} sx={{ ml: 0, fontSize: 12 }} >
                          { description } :
                        </Typography>
                      </span>
                      <span className="gatewayUrlSpan">
                        <Tooltip placement="top" title={ url_prefix + hash } arrow
                                 TransitionComponent={Zoom} TransitionProps={{ timeout: 600 }} >
                          <p className="gatewayUrlPara">
                            <Link href={ url_prefix + hash } rel="noreferrer" target="_blank" >{ url_prefix + shortHash }</Link>
                          </p>
                        </Tooltip>
                      </span>
                    </ListItem>);
        } else if (!!hash) {
            // here for just the raw hash. show a copy button
            return (<ListItem key={idx} sx={{ mt: 0, mb: 0, pt: 0, pb: 0, "paddingLeft": "0px" }} >
                      <span className="gatewayDescSpan">
                        <Typography paragraph={true} sx={{ ml: 0, fontSize: 12 }} >
                          { description } :
                        </Typography>
                      </span>
                        <span className="gatewayUrlSpan">
                          <button className="clickableHashLink button" type="button"
                                  onClick={ onCopy() }>
                            <Tooltip placement="top" title={rawHashCopyTip} arrow
                                     TransitionComponent={Zoom} TransitionProps={{ timeout: 600 }} >
                              <Typography paragraph={false} sx={{ ml: 0, fontSize: 10 }} >
                                { hash }
                              </Typography>
                            </Tooltip>
                          </button>
                          <Snackbar open={showCopySnackbar} autoHideDuration={2000} onClose={closeCopySnackbar} >
                            <Alert onClose={closeCopySnackbar} severity="success" sx={{ width: '100%' }}>
                              Copied
                            </Alert>
                          </Snackbar>
                        </span>
                    </ListItem>);
        } else {
            return (
                      <FormControlLabel label={ description + " (" + url_prefix + ")" } control={<Radio />} value={ idx } />
                   );
        }
    };


    const GatewaysList = (props) => {
        if (!!hash) {
            return (<List id="gatewaysList" >
                      {gateways.map((g, i) => (
                        <GatewayEntry key={i} description={g.description} url_prefix={g.url_prefix} idx={i} />
                      ))}
                      <GatewayEntry key={gateways.length} description="Raw Swarm Hash" url_prefix="" idx={gateways.length} />
                    </List>);
        } else {
            return (<FormControl>
                      <RadioGroup id="gatewaysList"
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue={selectedIdx}
                                  value={ selectedIdx }
                                  name="radio-buttons-group"
                                  onChange={onSelect}>
                        {gateways.map((g, i) => (
                          <GatewayEntry key={i} description={g.description} url_prefix={g.url_prefix} idx={i} />
                        ))}
                      </RadioGroup>
                    </FormControl>);
        }
    };


    return (
            <Dialog onClose={onClose} open={isOpen} fullWidth={true} maxWidth="md"
                    sx={{ "textAlign": "left", "minWidth": "fitContent", "maxWidth": "fitContent",
                    "& .MuiDialog-paper": {borderRadius: "0px", "padding": "1em", "bgcolor": "#e0e0e0" } }} >
              <DialogTitle>{title}</DialogTitle>
              <DialogContent sx={{ "overflow": "unset" }} >
                <DialogContentText>{message} </DialogContentText>
                <GatewaysList/>
              </DialogContent>
            </Dialog>
    );
};

GatewaysModal.propTypes = {
    message: PropTypes.string.isRequired,
};

export default GatewaysModal;
