/* ----------------------------------------------------------------------------------------------------- */
/* this is the top menu bar at the top of the page. it's mainly constructed using MUI/Appbar             */
/* it's main functionality is to provide a wallet connect button and menu links to other pages           */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState }          from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageDataSelector,
         setMode }                  from '../features/pageFeature';
import ConnectWalletButton          from './ConnectWalletButton';
import PostageCreditBox             from './PostageCreditBox';
import CoinSelectorBox              from './CoinSelectorBox';
import BalanceBox                   from './BalanceBox';

import logoImg                      from '../img/logo1.png';
import '../css/common.css';

/* ----------------------------------------------------------------------------------------------------- */
/* mui components                                                                                              */
/* ----------------------------------------------------------------------------------------------------- */
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';

function TopMenuBar(props) {

    const subtitle = props.subtitle;

    const pageData = useSelector(pageDataSelector);
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onHome  = () => { dispatch(setMode('landing')) };
    const onMenu0 = () => { dispatch(setMode('purchase')) };
    const onMenu1 = () => { dispatch(setMode('upload')) };
    const onMenu2 = () => { dispatch(setMode('view')) };

    return(
           <div id="menuBarDiv">
             <Box sx={{ flexGrow: 1 }}>
               <AppBar position="static">
                 <Toolbar>
                   <div>
                     <IconButton size="large" edge="start" color="inherit" aria-label="menu"
                                 aria-label="Top Level Menu"
                                 aria-controls="menu-appbar"
                                 aria-haspopup="true"
                                 onClick={handleMenu}
                                 sx={{ mr: 2 }} >
                       <MenuIcon />
                     </IconButton>
                     <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{vertical: 'top', horizontal: 'left',}}
                           keepMounted
                           transformOrigin={{vertical: 'top', horizontal: 'left',}}
                           open={Boolean(anchorEl)}
                           onClose={handleClose}>
                       <MenuItem onClick={onMenu0}>Purchase Postage</MenuItem >
                       <MenuItem onClick={onMenu1}>Upload Content to SWARM</MenuItem >
                       <MenuItem onClick={onMenu2}>View My Content</MenuItem>
                     </Menu>
                   </div>

                   <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                     <IconButton
                       size="large"
                       color="inherit"
                       onClick={onHome}
                       >
                       <img id="homeLogoImg" src={logoImg}/>
                     </IconButton>
                   </Box>

                   <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                     EZ-SWARM Gateway {subtitle}
                   </Typography>
                   <PostageCreditBox />
                   <CoinSelectorBox />
                   <BalanceBox />
                   <ConnectWalletButton />

                 </Toolbar>
               </AppBar>
             </Box>
           </div>
           );
}

export default TopMenuBar;
