/* ----------------------------------------------------------------------------------------------------- */
/* component shows a dialog for errors                                                                   */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState }            from "react";
import CircularProgress               from '@mui/material/CircularProgress';

import '../css/common.css';

const WaitIcon = (props) => {
    return (
	    <CircularProgress color="primary" thickness={7} />
    );
}

export default WaitIcon;
