const React = require("react");
const ReactDOM = require("react-dom");
import { Provider } from 'react-redux';
import store from './store';
import EasySwarmGateway from './EasySwarmGateway';
import "./css/common.css";

import CssBaseline from '@mui/material/CssBaseline';

const App = () => {
    return ( <EasySwarmGateway /> );
};

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
