import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios                             from "axios";

// needed for async when using bable
const regeneratorRuntime = require("regenerator-runtime");
const common = require('../lib/common');
const ether = require('../lib/ether');
const to = require('../lib/to');

export const ezProxyUrlPrefix  = "https://ez-swarm.com:4443/"

// initialize the content feature
// this needs to be called (once) before all other thunks
//
// note arg is the argument passed to the created thunk fcn
// note thunkAPI = { dispatch, getState, extra, rejectWithValue }
// where extra is the "extra argument" given to the thunk middleware on setup, if available
export const thunkInitContentFeature = createAsyncThunk(
    'contentData/thunkInitContentFeature',
    async (arg, thunkAPI) => {
        console.log('at least im in the thunkInitContentFeature thunk');
        const state = thunkAPI.getState();
        return new Promise((resolve, reject) => {
            axios.get(ezProxyUrlPrefix + "get-gateways")
                .then((rsp) => {
                    const info = rsp.data;
                    const err = common.arrayify(info).length == 0 ? "No response from server" : info.err;
                    if (!!err) {
                        console.log('server error = ' + err);
                        throw "Server error: " + err;
                    } else {
                        const gateways = info.gateways;
                        thunkAPI.dispatch(setGateways({ gateways: gateways }));
                        resolve('okey dokey');
                    }
                })
                .catch(err => {
                    console.error('thunkInitContentFeature err = ' + err + ', typeof(err) = ' + typeof(err));
                    thunkAPI.dispatch(setContentErrorStatus(err.toString()));
                    thunkAPI.rejectWithValue(err);
                });
        });
    }
)


//
// we maintain a global array of content; here we add one page into the array. pageFrom and pageTo are the lowest and highest
// pages that are already in the allContent array.
// note that pages can only be added to the beginning or the end of the allContent array.
//
function addNewContentPage(state, page, newContentList) {
    if (newContentList.length == 0) {
        return({ content:  state.contentData.content,
                 pageFrom: state.contentData.pageFrom,
                 pageTo:   state.contentData.pageTo,
               });
    }
    let pageFrom = state.contentData.pageFrom;
    let pageTo = state.contentData.pageTo;
    let allContent = state.contentData.content.slice();
    console.log('addNewContentPage: enter - allContent.length = ' +
                allContent.length + ", newContentList.length = " + newContentList.length);
    if (page > pageTo) {
        console.log("addNewContentPage: page " + page + " is greater than end");
        allContent.splice(allContent.length, 0, ...newContentList);
        pageTo = page;
        if (page < pageFrom)
            pageFrom = page;
    } else if (page < pageFrom) {
        console.log("addNewContentPage: page " + page + "is less than begin");
        allContent.splice(0, 0, ...newContentList);
        pageFrom = page;
        if (page > pageTo)
            pageTo = pageTo;
    } else {
        allContent = contentList;
        pageTo = pageFrom = page;
        console.log('addNewContentPage: replaced allContent!');
    }
    console.log('addNewContentPage: exit allContent.length = ' + allContent.length);
    return({ content: allContent, pageFrom: pageFrom, pageTo: pageTo});
}


//
// note thunkAPI = { dispatch, getState, extra, rejectWithValue }
// where extra is the "extra argument" given to the thunk middleware on setup, if available
//
export const thunkGetContent = createAsyncThunk(
    'contentData/thunkGetContent',
    async (arg, thunkAPI) => {
        const state = thunkAPI.getState();
        const page = arg.page;
        console.log('at least im in the thunkGetContent thunk; page = ' + page);
        return new Promise((resolve, reject) => {
            const addr = state.connectData.address
            axios.get(ezProxyUrlPrefix + "get-content?addr=" + addr +
                      "&page="     + page +
                      "&pageSize=" + state.contentData.pageSize +
                      "&sort="     + (state.contentData.sortTitleNotExp ? "title" : "expiration") +
                      "&order="    + (state.contentData.sortUpNotDown   ? "desc"   : "asc"))
                .then((rsp) => {
                    const info = rsp.data;
                    const err = common.arrayify(info).length == 0 ? "No response from server" : info.err;
                    if (!!err) {
                        console.log('server error = ' + err);
                        throw "Server error: " + err;
                    } else {
                        const content = info.content;
                        console.log("thunkGetContent: retreived " + content.length + " of " + content.contentItems + " items");
                        //console.log('thunkGetContent: content = ' + JSON.stringify(content));
                        const newContent = addNewContentPage(state, content.page, content.contentItems);
                        console.log('AAA');
                        thunkAPI.dispatch(setContent({ contentCount: content.contentcount,
                                                       content: newContent.content,
                                                       pageFrom: newContent.pageFrom,
                                                       pageTo: newContent.pageTo }));
                        console.log('BBB');
                        resolve('okey dokey');
                    }
                })
                .catch(err => {
                    console.error('thunkGetContent err = ' + err + ', typeof(err) = ' + typeof(err));
                    console.log('CCC');
                    thunkAPI.dispatch(setContentErrorStatus(err.toString()));
                    console.log('DDD');
                    thunkAPI.rejectWithValue(err);
                });
        });
    }
)


export const thunkDelContent = createAsyncThunk(
    'contentData/thunkDelContent',
    async (arg, thunkAPI) => {
        const state = thunkAPI.getState();
        const hash = arg.hash;
        const stamp = arg.stamp;
        console.log('at least im in the thunkDelContent thunk; hash = ' + hash);
        return new Promise((resolve, reject) => {
            const addr = state.connectData.address
            axios.get(ezProxyUrlPrefix + "del-content?addr=" + addr +
                      "&hash="  + hash +
                      "&stamp=" + stamp)
                .then((rsp) => {
                    const info = rsp.data;
                    console.log("thunkDelContent: info = " + JSON.stringify(info));
                    const err = JSON.stringify(info).length == 0 ? "No response from server" : info.err;
                    if (!!err) {
                        console.log('server error = ' + err);
                        throw "Server error: " + err;
                    } else {
                        console.log("thunkDelContent: no error from server");
                        thunkAPI.dispatch(unInitContentFeature());
                        resolve('okey dokey');
                    }
                })
                .catch(err => {
                    console.error('thunkDelContent err = ' + err + ', typeof(err) = ' + typeof(err));
                    console.log('CCC');
                    thunkAPI.dispatch(setContentErrorStatus(err.toString()));
                    console.log('DDD');
                    thunkAPI.rejectWithValue(err);
                });
        });
    }
)


export const thunkSetSort = createAsyncThunk(
    'contentData/thunkSetSort',
    async (arg, thunkAPI) => {
        console.log('at least im in the thunkSetSort thunk');
        const state = thunkAPI.getState();
        const sortTitleNotExp = arg.sortTitleNotExp;
        const sortUpNotDown = arg.sortUpNotDown;
        return new Promise((resolve, reject) => {
            try {
                if (state.contentData.content.length == state.contentData.contentCount) {
                    // we have all the content locally, so we can just sort it ourselves
                    const sortedContent = state.contentData.content.toSorted((a, b) => {
                        // >0 => sort a after b
                        // <0 => sort a before b
                        // =0 => keep original order of a and b
                        // down means ascending ('apple' before 'baby')
                        const downABeforeB =  sortTitleNotExp ? (a.title < b.title) : (a.expiration < b.expiration);
                        const downABeforeBResult = downABeforeB ? -1 : +1;
                        const result = sortUpNotDown ? (-1 * downABeforeBResult) : downABeforeBResult;
                        return result;
                    });
                    //console.log('thunkSetSort sorted = ' + JSON.stringify(sortedContent));
                    thunkAPI.dispatch(setSort({ sortedContent:   sortedContent,
                                                sortTitleNotExp: sortTitleNotExp,
                                                sortUpNotDown:   sortUpNotDown }));
                } else {
                    // we need to get all new content, since the early pages of the old content
                    thunkAPI.dispatch(setSort({ sortedContent:   [],
                                                sortTitleNotExp: sortTitleNotExp,
                                                sortUpNotDown:   sortUpNotDown }))
                    thunkAPI.dispatch(thunkGetContent({ page: 0 }));
                }
                resolve('okey dokey');
            } catch(err) {
                console.error('thunkSetSort err = ' + err + ', typeof(err) = ' + typeof(err));
                thunkAPI.dispatch(setContentErrorStatus(err.toString()));
                thunkAPI.rejectWithValue(err);
            }
        });
    }
)

//
// for each entry in content[],
// item = { title, hash=, postageId, expiration}
//
export const contentSlice = createSlice({
    name: 'contentData',
    initialState: {
        beenInitialized: false,
        changeMarker: 0,
        pending: false,
        contentCount: 0,
        gateways: [],
        content: [],
        contentError: "",
        sortTitleNotExp: true,
        sortUpNotDown: false,
        forwardDone: false,
        backwardDone: false,
        pageSize: 15,
        pageTo: -1,
        pageFrom: Number.MAX_SAFE_INTEGER,
    },
    reducers: {
        setGateways: (state, action) => {
            state.gateways = action.payload.gateways;
            state.beenInitialized = true;
            state.pageTo = -1;
            state.pageFrom = Number.MAX_SAFE_INTEGER;
            ++state.changeMarker;
        },
        unInitContentFeature: (state, action) => {
            state.pending = false;
            state.contentCount = 0;
            state.gateways = [];
            state.content = [];
            state.contentError = "";
            state.beenInitialized = false;
            ++state.changeMarker;
            state.pageTo = -1;
            state.pageFrom = Number.MAX_SAFE_INTEGER;
        },
        setPending: (state, action) => {
            state.pending = action.payload;
            ++state.changeMarker;
        },
        setContentIsOld: (state, action) => {
            state.contentIsOld = true;
            ++state.changeMarker;
        },
        setContent: (state, action) => {
            state.content = action.payload.content;
            state.contentCount = action.payload.contentCount;
            state.pageFrom = action.payload.pageFrom;
            state.pageTo = action.payload.pageTo;
            state.pending = false;
            ++state.changeMarker;
        },
        setContentErrorStatus: (state, action) => {
            state.contentError = action.payload;
            state.forwardDone = false;
            state.backwardDone = false;
            state.pending = false;
            ++state.changeMarker;
        },
        setSort: (state, action) => {
            state.content = action.payload.sortedContent,
            state.sortTitleNotExp = action.payload.sortTitleNotExp;
            state.sortUpNotDown = action.payload.sortUpNotDown;
            if (state.content.length == 0) {
                state.pageTo = -1;
                state.pageFrom = Number.MAX_SAFE_INTEGER;
            }
            ++state.changeMarker;
        }
    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        // thunks will have X.pending, X.fulfilled, X.rejected
        [thunkGetContent.pending]:    (state, action) => { state.pending = true;  ++state.changeMarker },
        [thunkGetContent.fulfilled]:  (state, action) => { },
        [thunkGetContent.rejected]:   (state, action) => { state.pending = false; ++state.changeMarker },
    },
})

export const {
    setGateways,
    unInitContentFeature,
    setContent,
    setContentIsOld,
    setContentErrorStatus,
    setSort,
    setPending,
} = contentSlice.actions;

export const contentDataSelector = state => state.contentData;
export default contentSlice.reducer;
