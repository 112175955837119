import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios                             from "axios";
// needed for async when using bable
const regeneratorRuntime = require("regenerator-runtime");

import { thunkRefreshPostageCredit }     from "./connectFeature";

const BN = require("bn.js");
const beejs = require("@ethersphere/bee-js");
const common = require('../lib/common');
const ether = require('../lib/ether');
const to = require('../lib/to');

// we use an https proxy to get to the swarm gateway to avoid mixed cotent from the https dApp
const ezSwarmGateway = "https://ez-swarm.com:633";
export const ezProxyUrlPrefix  = "https://ez-swarm.com:4443/"
export const ezSwarmGatewayUrlPrefix  = ezSwarmGateway + "/bzz/"

//
// hashToRetrieveUrl
//
export const hashToRetrieveUrl = (hash) => {
    // eg: https://ez-swarm.com:1633/ \
    //     bzz/b6f6c0a6ab9f981ca6e01e4c7e6b1d751df77bec89a948189550bbe5c7263b49/
    return(ezSwarmGatewayUrlPrefix + hash + "/");
}

let bee = null;
let swarmTimeout = 4000;


// initialize the upload feature
// this needs to be called (once) before all other thunks
//
// note arg is the argument passed to the created thunk fcn
// note thunkAPI = { dispatch, getState, extra, rejectWithValue }
// where extra is the "extra argument" given to the thunk middleware on setup, if available
export const thunkInitUploadFeature = createAsyncThunk(
    'uploadData/thunkInitUploadFeature',
    async (arg, thunkAPI) => {
        console.log('at least im in the thunkInitUploadFeature thunk');
        const state = thunkAPI.getState();
        return new Promise((resolve, reject) => {
            if (!bee) {
                try {
                    bee = new beejs.Bee(ezSwarmGateway);
                    console.log('thunkInitUploadFeature: bee = ' + !!bee + ', gateway = ' + ezSwarmGateway);
                } catch (err) {
                    console.error('thunkInitUploadFeature err = ' + err);
                    thunkAPI.rejectWithValue(err);
                    reject(err);
                    return;
                }
            }
            resolve('okey dokey');
        });
    }
);


//
// there are certain variables and functions that are common to any long-tasks (ie tasks
// that might take a minute or more):
//  longTaskProgress:    counter that runs from 0 to 100
//                         0    => task has not bugun
//                         1-99 => task is running
//                         100  => task is done
//  longTaskDoneInfo:    info related to successful task completion (ie. hash after upload)
//  longTaskErrorStatus: info related to error that aborted task

//
// timer for any long-task
//
let longTaskProgressTimer = null;
async function updateLongTaskProgress(dispatch, getState) {
    let newProgress = 0;
    const state = getState();
    if (state.uploadData.longTaskProgress == 0 || state.uploadData.longTaskProgress == 100) {
        longTaskProgressTimer = null;
        console.log('updateLongTaskProgress: in timer; newProgress = ' + newProgress + '; all done');
    } else {
        const diff = Math.random() * 10;
        newProgress = Math.min(state.uploadData.longTaskProgress + diff, 100);
        if (newProgress >= 100)
            newProgress = 1;
        console.log('updateLongTaskProgressL: in timer; newProgress = ' + newProgress);
        dispatch(setLongTaskProgress(newProgress));
        longTaskProgressTimer = setTimeout(function() {
            updateLongTaskProgress(dispatch, getState);
        }, 5000);
    }
}

//
// upload a local file to our swarm gateway
//
export const thunkExecuteUpload = createAsyncThunk(
    'uploadData/thunkExecuteUpload',
    async (arg, thunkAPI) => {
        console.log('at least im in the thunkExecuteUpload thunk');
        const state = thunkAPI.getState();
        return new Promise((resolve, reject) => {
            try {
                console.log("thunkExecuteUpload: swarm = " + !!bee);
                console.log("thunkExecuteUpload: uploadFileName = " + state.uploadData.uploadFileName +
                            ", (" + encodeURIComponent(state.uploadData.uploadFileName) + ")");
                console.log("thunkExecuteUpload: uploadFileData = " + state.uploadData.uploadFileData.substring(0, 0x80));
                const colonIdx = state.uploadData.uploadFileData.indexOf(":");
                const idx = state.uploadData.uploadFileData.indexOf(",");
                const semicolonIdx = state.uploadData.uploadFileData.indexOf(";");
                const base64Str = state.uploadData.uploadFileData.substring(idx + 1);
                const mimeType = state.uploadData.uploadFileData.substring(colonIdx + 1, semicolonIdx);
                console.log("mimeType = " + mimeType);
                console.log("base64Str = " + base64Str.substring(0, 20));
                const imageBuf = Buffer.from(base64Str, 'base64')
                //every 4 base64 chars is 24 bits
                const bytes = new Uint8Array( (base64Str.length / 4) * 3);
                bytes.set(imageBuf);
                // uploadFile passing a File foesn't work :(. so we'll just pass the Uint8Array
                // const file = new File( [ bytes ],                                           // data
                //                        encodeURIComponent(state.uploadData.uploadFileName), // name
                //                        { type: mimeType })                                  // mime
                thunkAPI.dispatch(setLongTaskErrorStatus(""));
                thunkAPI.dispatch(setLongTaskMilestone("awaiting user's signature..."));
                const message = "Authorization for ez-swarm.com to upload content to SWARM from this account\n";
                let signature = "";
                let stamp = "";
                ether.signMessage(message)
                    .then(s => {
                        signature = s;
                        console.log("signature is: " + signature + "\n");
                        longTaskProgressTimer = setTimeout(function() {
                            updateLongTaskProgress(thunkAPI.dispatch, thunkAPI.getState);
                        }, 5000);
                        thunkAPI.dispatch(setLongTaskMilestone("retreiving provisional postage from SWARM gateway..."));
                        return(axios.get(ezProxyUrlPrefix + "stap?signature=" + signature));
                    })
                    .then((rsp) => {
                        // eg: {"stap":
                        //       {
                        //        "tag":  { "uid":2223784477,
                        //                  "startedAt":"2022-12-08T23:47:54.476360429Z",
                        //                  "total":0,
                        //                  "processed":0,
                        //                  "synced":0
                        //                },
                        //        "postageId":"6d956ca47ead9fcb96f738669cfd8d8aadbe655b9f2b165dffcc84f6fdfd9628"
                        //       },"err":null
                        //     }
                        const info = rsp.data;
                        const err = common.arrayify(info).length == 0 ? "No response from server" : info.err;
                        if (err) {
                            throw err;
                        } else {
                            const stap = info.stap;
                            console.log('thunkExecuteUpload: stap = ' + JSON.stringify(stap));
                            stamp = stap.postageId;
                            console.log("thunkExecuteUpload: uploading file; stamp = ", stamp);
                            thunkAPI.dispatch(setLongTaskMilestone("sending file to SWARM gateway..."));
                            // uploadFile passing a File foesn't work :(. The content is always {}. so just pass the Uint8Array
                            // and set the contentType
                            // return(bee.uploadFile(stamp, file));
                            return(bee.uploadFile(stamp,
                                                  bytes,
                                                  encodeURIComponent(state.uploadData.uploadFileName),
                                                  { contentType: mimeType }
                                                 ));

                        }
                    })
                    .then(result => {
                        console.log("thunkExecuteUpload: uploaded file. Address:", result);
                        // eg: ef5c8d388b7fde1bc04cad403aee5d997810dc12d7503f24793eb2949a3d68ed
                        if (!result)
                            throw ('No SWARM hash received!');
                        console.log("signature is: " + signature + "\n");
                        thunkAPI.dispatch(setLongTaskMilestone("binding file to postage..."));
                        return(axios.get(ezProxyUrlPrefix + "post?signature=" + signature +
                                         "&hash=" + result.reference + "&stamp=" + stamp +
                                         "&title=" + state.uploadData.uploadFileName));
                    })
                    .then(rsp => {
                        // eg: {"info":
                        //       {
                        //        "hash": <hash>,
                        //        "err" : null
                        //       }
                        //     }
                        const info = rsp.data;
                        const err = common.arrayify(info).length == 0 ? "No response from server" : info.err;
                        if (err) {
                            throw err;
                        } else {
                            console.log('thunkExecuteUpload: info = ' + JSON.stringify(info));
                            thunkAPI.dispatch(setLongTaskDoneInfo( { doneInfo: info.hash, milestone: "binding file to postage... complete" } ));
                            thunkAPI.dispatch(thunkRefreshPostageCredit({ addr: state.connectData.address }));
                            resolve('okey dokey');
                        }
                    })
                    .catch(err => {
                        if (typeof err === 'object')
                            err = JSON.stringify(err);
                        if (typeof err !== 'string')
                            err = err.toString();
                        console.error('thunkExecuteUpload: swarm upload error: ' + err);
                        console.error('thunkExecuteUpload: swarm upload error: ' + JSON.stringify(err));
                        thunkAPI.dispatch(setLongTaskErrorStatus("Error: " + err));
                        thunkAPI.rejectWithValue(err);
                        reject(err);
                        return;
                    });
            } catch (err) {
                console.error('thunkExecuteUpload err = ' + err);
                thunkAPI.dispatch(setLongTaskErrorStatus("Error: " + err));
                thunkAPI.rejectWithValue(err);
            }
        });
    }
)

export const thunkTopOffContent = createAsyncThunk(
    'uploadData/thunkTopOffContent',
    async (arg, thunkAPI) => {
        console.log('at least im in the thunkTopOffContent thunk');
        const hash = arg.hash;
        const stamp = arg.stamp;
        const credits = arg.credits;
        const state = thunkAPI.getState();
        return new Promise((resolve, reject) => {
            try {
                console.log("thunkTopOffContent: swarm = " + !!bee);
                console.log("thunkTopOffContent: hash = " + hash);
                console.log("thunkTopOffContent: credits = " + credits);
                thunkAPI.dispatch(setLongTaskErrorStatus(""));
                thunkAPI.dispatch(setLongTaskMilestone("awaiting user's signature..."));
                const message = "Authorization for ez-swarm.com to add " + credits +
                      " credits to content with hash " + hash + "\n";
                let signature = "";
                ether.signMessage(message)
                    .then(s => {
                        signature = s;
                        console.log("signature is: " + signature + "\n");
                        longTaskProgressTimer = setTimeout(function() {
                            updateLongTaskProgress(thunkAPI.dispatch, thunkAPI.getState);
                        }, 5000);
                        thunkAPI.dispatch(setLongTaskMilestone("supplementing postage via SWARM gateway..."));
                        return(axios.get(ezProxyUrlPrefix + "top-off?signature=" + signature +
                                         "&hash=" + hash + "&stamp=" + stamp +
                                         "&credits=" + credits));
                    })
                    .then((rsp) => {
                        const info = rsp.data;
                        // eg: info = {
                        //              "hash" : "xxxxx...",
                        //              "err"  : null,
                        //              "expiration" : 1327892
                        //            }
                        const err = common.arrayify(info).length == 0 ? "No response from server" : info.err;
                        if (err) {
                            throw err;
                        } else {
                            console.log('thunkTopOffContent: info = ' + JSON.stringify(info));
                            thunkAPI.dispatch(setLongTaskDoneInfo({ doneInfo: info.expiration.toString(),
                                                                    milestone: "suplimenting postage via SWARM gateway... complete" } ));
                            thunkAPI.dispatch(thunkRefreshPostageCredit({ addr: state.connectData.address }));
                            resolve('okey dokey');
                        }
                    })
                    .catch((err, status) => {
                        //console.error('thunkTopOffContent: longTask upload error: ' + err.toString());
                        console.error('thunkTopOffContent: longTask upload error: ' + err);
                        thunkAPI.dispatch(setLongTaskErrorStatus("Error: " + err));
                        thunkAPI.rejectWithValue(err);
                        reject(err);
                        return;
                    });
            } catch (err) {
                console.error('thunkTopOffContent err = ' + err);
                thunkAPI.dispatch(setLongTaskErrorStatus("Error: " + err));
                thunkAPI.rejectWithValue(err);
            }
        });
    }
)



export const uploadSlice = createSlice({
    name: 'uploadData',
    initialState: {
        changeMarker:          0,
        beenInitialized:       false,
        longTaskProgress:      0,
        longTaskMilestone:     "",
        uploadFileName:        "",
        uploadFileData:        "",
        longTaskDoneInfo:      "",
        longTaskErrorStatus:   "",
    },
    reducers: {
        unInitUploadFeature: (state, action) => {
            state.beenInitialized = false;
            state.bondSuccessMessage = "";
            state.longTaskErrorStatus = "";
            state.bondErrorStatus = "";
            state.extractStatus = "";
            state.longTaskDoneInfo = "";
            ++state.changeMarker;
        },
        setUploadFile: (state, action) => {
            state.uploadFileName = action.payload.name;
            state.uploadFileData = action.payload.data;
            console.log("setUploadFile: uploadFileData = " + state.uploadFileData.substring(0, 0x80));
            ++state.changeMarker;
        },
        // setting milestone resets progress to 1
        setLongTaskMilestone: (state, action) => {
            state.longTaskMilestone = action.payload;
            state.longTaskProgress = 1;
            ++state.changeMarker;
        },
        setLongTaskProgress: (state, action) => {
            state.longTaskProgress = action.payload;
            ++state.changeMarker;
        },
        // we always update milestone when we set done info
        setLongTaskDoneInfo: (state, action) => {
            state.longTaskDoneInfo = action.payload.doneInfo;
            state.longTaskMilestone = action.payload.milestone;
            state.longTaskErrorStatus = ""
            ++state.changeMarker;
        },
        setLongTaskErrorStatus: (state, action) => {
            state.longTaskErrorStatus = action.payload;
            state.longTaskDoneInfo = "";
            ++state.changeMarker;
        },
    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        // thunks will have X.pending, X.fulfilled, X.rejected
        [thunkInitUploadFeature.fulfilled]: (state, action) => { state.beenInitialized = true;    ++state.changeMarker;    },
        [thunkExecuteUpload.pending]:       (state, action) => { state.longTaskProgress = 1;      ++state.changeMarker; },
        [thunkExecuteUpload.fulfilled]:     (state, action) => { state.longTaskProgress = 100;    ++state.changeMarker; },
        [thunkExecuteUpload.rejected]:      (state, action) => { state.longTaskProgress = 0;      ++state.changeMarker; },
        [thunkTopOffContent.pending]:       (state, action) => { state.longTaskProgress = 1;      ++state.changeMarker; },
        [thunkTopOffContent.fulfilled]:     (state, action) => { state.longTaskProgress = 100;    ++state.changeMarker; },
        [thunkTopOffContent.rejected]:      (state, action) => { state.longTaskProgress = 0;      ++state.changeMarker; },
    },
})

export const {
    unInitUploadFeature,
    setUploadFile,
    setLongTaskDoneInfo,
    setLongTaskMilestone,
    setLongTaskProgress,
    setLongTaskErrorStatus,
} = uploadSlice.actions;

export const uploadDataSelector = state => state.uploadData;
export default uploadSlice.reducer;
