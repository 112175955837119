/* ----------------------------------------------------------------------------------------------------- */
/* this is the purchase page for the Easy Swarm Gateway App                                              */
/* it's main functionality is to send funds to the Easy Swarm backend to be credited to the user's acct. */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState, useEffect  } from 'react';
import { useDispatch, useSelector }    from 'react-redux';
import { pageDataSelector,
         reloadPage,
         setMode }                     from '../features/pageFeature';
import { purchaseDataSelector,
         unInitPurchaseFeature,
         setPurchaseSuccess,
         thunkInitPurchaseFeature,
         thunkDoPurchase }             from "../features/purchaseFeature";
import { connectDataSelector,
         thunkReadBalances,
         thunkRefreshPostageCredit }   from "../features/connectFeature";
import TopMenuBar                      from './TopMenuBar';
import CustomTheme                     from './CustomTheme';
import TxidProgressCard                from './TxidProgressCard';
import ErrorDialog                     from './ErrorDialog';
import MessageDialog                   from './MessageDialog';
import LinearProgressWithLabel         from './LinearProgressWithLabel';

import '../css/common.css';
import '../css/purchasePage.css';

const common = require('../lib/common');

/* ----------------------------------------------------------------------------------------------------- */
/* mui container and friends                                                                             */
/* ----------------------------------------------------------------------------------------------------- */
import Box                      from '@mui/material/Box';
import Chip                     from '@mui/material/Chip';
import Container                from '@mui/material/Container';
import Grid                     from '@mui/material/Grid';
import List                     from '@mui/material/List';
import ListItem                 from '@mui/material/ListItem';
import ListItemText             from '@mui/material/ListItemText';
import TextField                from '@mui/material/TextField';
import Typography               from '@mui/material/Typography';
import { ThemeProvider }        from '@mui/material/styles';
import { positions }            from '@mui/system';

/* ----------------------------------------------------------------------------------------------------- */
/* mui forms                                                                                             */
/* ----------------------------------------------------------------------------------------------------- */
import Button                   from '@mui/material/Button';
import Tooltip                  from '@mui/material/Tooltip';
import Input                    from '@mui/material/Input';
import FilledInput              from '@mui/material/FilledInput';
import OutlinedInput            from '@mui/material/OutlinedInput';
import InputLabel               from '@mui/material/InputLabel';
import InputAdornment           from '@mui/material/InputAdornment';
import InputUnstyled            from '@mui/base/InputUnstyled';
import Switch                   from '@mui/material/Switch';
import FormHelperText           from '@mui/material/FormHelperText';
import FormControlLabel         from '@mui/material/FormControlLabel';
import FormControl              from '@mui/material/FormControl';
import FormLabel                from '@mui/material/FormLabel';
import TextareaAutosize         from '@mui/material/TextareaAutosize';

let pageChangeMarkerPrev     = -1;
let purchaseChangeMarkerPrev = -1;
let connectChangeMarkerPrev  = -1;
let purchaseProgressDivClass = "hidden";
let purchase1ButtonEnable    = false;
let purchase10ButtonEnable   = false;
let purchase100ButtonEnable  = false;
let purchase1ButtonTip       = "";
let purchase10ButtonTip      = "";
let purchase100ButtonTip     = "";
let purchasedAmountMessage   = "";
let activeTxid               = "";
let txClock                  = "";
let errorMessage             = "";
let modalMessage             = "";
let modalTitle               = "";
let errorSeverity            = "success";
let coinBalance              = "0.000";
let coinName                 = "DAI";

function PurchasePage() {
    const pageData = useSelector(pageDataSelector);
    const purchaseData = useSelector(purchaseDataSelector);
    const connectData = useSelector(connectDataSelector);
    const dispatch = useDispatch();

    const beenRendered = !!document.getElementById('rootGrid');

    // if puchase data hasn't been initialized, and hasn't failed initialization, then initialize now
    useEffect(() => {
        if (!purchaseData.beenInitialized) {
            purchaseProgressDivClass = "hidden";
            if (!purchaseData.purchaseErrorStatus)
                dispatch(thunkInitPurchaseFeature());
            else
                console.log("PurchasePage: error prevents initialization! (" + purchaseData.purchaseErrorStatus + ")");
        }
    }, [ purchaseData.changeMarker ] );

    if (beenRendered) {
        console.log("pageMode = " + pageData.pageMode);
        if (pageData.pageMode == 'purchase' && ((pageChangeMarkerPrev     != pageData.changeMarker    ) ||
                                                (purchaseChangeMarkerPrev != purchaseData.changeMarker) ||
                                                (connectChangeMarkerPrev  != connectData.changeMarker )) ) {
            if (pageChangeMarkerPrev != pageData.changeMarker) {
                purchaseProgressDivClass = "hidden";
            }
            pageChangeMarkerPrev     = pageData.changeMarker;
            purchaseChangeMarkerPrev = purchaseData.changeMarker;
            connectChangeMarkerPrev = connectData.changeMarker;

            purchase100ButtonEnable = purchase10ButtonEnable = purchase10ButtonEnable = false;
            if (!!(errorMessage = purchaseData.purchaseErrorStatus)) {
                errorSeverity = "error";
                purchase1ButtonTip = purchase10ButtonTip = purchase100ButtonTip = "Error prevents purchase";
            } else if (!connectData.network) {
                purchase1ButtonTip = purchase10ButtonTip = purchase100ButtonTip = "You need to connect your wallet";
            } else if (!!connectData.connectErrorStatus) {
                purchase1ButtonTip = purchase10ButtonTip = purchase100ButtonTip = "Unable to communicate with ez-swarm server";
            } else if (!!purchaseData.beenInitialized) {
                if (connectData.isDaiNotPyusd) {
                    coinName = "DAI";
                    coinBalance = common.weiToDecimal(connectData.daiBalanceWei, 3, 'ether');
                } else {
                    coinName = "PYUSD";
                    coinBalance = common.weiToDecimal(connectData.pyusdBalanceWei, 3, 'mwei');
                }
                purchase1ButtonTip = purchase10ButtonTip = purchase100ButtonTip = "You don't have sufficient " + coinName + " for this purchase";
                const effectiveAmount = (coinBalance < 1) ? 0 : (coinBalance < 10) ? 1 :  (coinBalance < 100) ? 10 : 100;
                switch (effectiveAmount) {
                case 100:
                    purchase100ButtonTip = "Click to initiate a purchase of one hundred postage credit for one hundred " + coinName;
                    purchase100ButtonEnable = true;
                case 10:
                    purchase10ButtonTip = "Click to initiate a purchase of ten postage credits for ten " + coinName;
                    purchase10ButtonEnable = true;
                case 1:
                    purchase1ButtonTip = "Click to initiate a purchase of one postage credit for one " + coinName;
                    purchase1ButtonEnable = true;
                    break;
                default:
                    break;
                }
            }
            activeTxid = purchaseData.activeTxid;
            txClock = purchaseData.activeTxProgress.toString();
            if (!!(modalTitle = purchaseData.purchaseSuccessStatus))
                modalMessage = purchasedAmountMessage;
        }
    }

    const onErrorClose = () => {
        console.log("PurchasePage: got onErrorClose()");
        dispatch(unInitPurchaseFeature());
    };

    const onMessageClose = () => {
        console.log("PurchasePage: got onMessageClose()");
        modalMessage = "";
        purchaseProgressDivClass = "hidden";
        dispatch(setPurchaseSuccess(''));
        dispatch(thunkRefreshPostageCredit({ addr: connectData.address }));
        dispatch(thunkReadBalances());
    };

    //
    // execute purchase
    //
    const onPurchaseExecute = (amount) => () => {
        console.log("onPurchaseExecute: got " + amount);
        purchasedAmountMessage = "Purchase amount: " + amount + " EZ-Swarm Postage Credit";
        purchaseProgressDivClass = "visible";
        dispatch(thunkDoPurchase({ sendAmount: amount }));
    };

    return (
            <div id="rootGrid">
              <TxidProgressCard txid={activeTxid} clock={txClock} />
              <ErrorDialog message={errorMessage} closeAction={onErrorClose} severity={errorSeverity} />
              <MessageDialog message={modalMessage} closeAction={onMessageClose} title={modalTitle} />
              <TopMenuBar subtitle=" - Purchase Postage"/>
              <div id="pageDiv">
                <div id="contentAreaDiv">
                  <ThemeProvider theme={CustomTheme}>
                    <Container fixed sx={{ bgcolor: 'primary.main', color: 'primary.contrast' }}>
                      <List>
                        <ListItem key={0}>
                          <Typography gutterBottom={false}
                                      variant="h6">Purchase Postage Credits</Typography>
                        </ListItem>
                        <ListItem key={1}>
                          <Typography gutterBottom={true}
                                      variant="body1">Here you can purchase "postage credits", which will allow you to post content on SWARM. When you post content to
                            SWARM, the content is actually split into many encrypted pieces (or "chunks"), which are distributed and stored on thousands of servers around
                            the world. Each server that stores a chunk of data is paid with postage. When you purchase postage on this page, it is credited to your account
                            on the EZ-Swarm gateway. Note that your account is identified by your Ethereum address.</Typography>
                        </ListItem>
                        <ListItem key={2}>
                          <Typography gutterBottom={false}
                                      variant="h6">Cost</Typography>
                        </ListItem>
                        <ListItem key={3}>
                          <Typography gutterBottom={true}
                                      variant="body1">The cost of one postage credit on the EZ-Swarm gateway is 1 USD (Stablecoin). You can pay with DAI
                            or PYUSD (selectable in the top menubar), on the Ethereum Mainnet.</Typography>
                        </ListItem>
                      </List>
                      <div id="purchaseAreaDiv">
                        <span className="purchase100CreditButtonSpan">
                          <Tooltip placement="top-end" title={purchase100ButtonTip}>
                            <div id="purchase100ButtonDiv" disabled={!purchase100ButtonEnable} >
                              <Button variant="contained" id="purchase100Button" disabled={!purchase100ButtonEnable}
                                      onClick={onPurchaseExecute(100)}>Purchase 100 Postage Credits</Button>
                            </div>
                          </Tooltip>
                        </span>
                        <span className="purchase10CreditButtonSpan">
                          <Tooltip placement="top-end" title={purchase10ButtonTip}>
                            <div id="purchase10ButtonDiv" disabled={!purchase10ButtonEnable} >
                              <Button variant="contained" id="purchase10Button" disabled={!purchase10ButtonEnable}
                                      onClick={onPurchaseExecute(10)}>Purchase 10 Postage Credits</Button>
                            </div>
                          </Tooltip>
                        </span>
                        <span className="purchase1CreditButtonSpan">
                          <Tooltip placement="top-end" title={purchase1ButtonTip}>
                            <div id="purchase1ButtonDiv" disabled={!purchase1ButtonEnable} >
                              <Button variant="contained" id="purchase1Button" disabled={!purchase1ButtonEnable}
                                      onClick={onPurchaseExecute(1)}>Purchase 1 Postage Credit</Button>
                            </div>
                          </Tooltip>
                        </span>
                      </div>
                      <div id="purchaseProgressDiv" className={purchaseProgressDivClass}>
                        <Box sx={{ width: '100%' }}>
                          <LinearProgressWithLabel value={purchaseData.activeTxProgress} />
                        </Box>
                      </div>
                    </Container>
                  </ThemeProvider>
                </div>
              </div>
            </div>
    );
}

export default PurchasePage;
