import { createTheme } from '@mui/material/styles';
const CustomTheme = createTheme({
    palette: {
	primary: {
	    main: '#fff',
	    contrastText: '#1976d2',
	},
    },
});

//
// could use, eg:
//  mode: 'dark',
// <Container fixed sx={{ bgcolor: 'background.paper', color: 'primary.main' }}>
// <Container fixed sx={{ bgcolor: 'custom.main', color: 'custom.contrast' }}>

export default CustomTheme;
