/* ----------------------------------------------------------------------------------------------------- */
/* component shows the DAI / PYUSD coin-selector switch in a box                                         */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState }            from "react";
import { useDispatch, useSelector }   from "react-redux";
import PropTypes                      from 'prop-types';
import { connectDataSelector,
         setIsDaiNotPyusd,
         thunkGetPostageCredit,
         thunkRefreshPostageCredit }  from "../features/connectFeature";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControlLabel               from '@mui/material/FormControlLabel';
import FormControl                    from '@mui/material/FormControl';
import FormLabel                      from '@mui/material/FormLabel';
import Switch                         from '@mui/material/Switch';
import Tooltip                        from '@mui/material/Tooltip';

import '../css/common.css';
const common    = require('../lib/common');
const ether     = require('../lib/ether');

let connectChangeMarkerPrev = -1;
let coinSelectorBoxDivClass = "hidden";
let daiPyusdSwitchTip       = "";
let daiPyusdSwitchLabel     = "DAI"
let daiPyusdSwitchDisabled  = true;
let isDaiNotPyusd           = true;


const CoinSelectorBox = (props) => {

    const dispatch = useDispatch();
    const connectData = useSelector(connectDataSelector);

    const beenRendered = !!document.getElementById('rootGrid');
    if (beenRendered) {
        if (connectChangeMarkerPrev != connectData.changeMarker) {
            console.log("CoinSelectorBox: change detected");
            connectChangeMarkerPrev  = connectData.changeMarker;
            if (!connectData.network) {
                coinSelectorBoxDivClass = "hidden";
            } else {
                coinSelectorBoxDivClass = "visible" ;
                if (!!connectData.connectErrorStatus) {
                    daiPyusdSwitchLabel = "error";
                    daiPyusdSwitchDisabled = true;
                } else {
                    isDaiNotPyusd = connectData.isDaiNotPyusd;
                    console.log("CoinSelectorBox: isDaiNotPyusd = " + isDaiNotPyusd);
                    daiPyusdSwitchDisabled = false;
                    daiPyusdSwitchLabel = isDaiNotPyusd ? "DAI" : "PYUSD";
                }
            }
        }
    }

    const onDaiPyusdExecute = (event) => {
        console.log("CoinSelectorBox: got onDaiPyusdExecute() checked = " + event.target.checked);
        dispatch(setIsDaiNotPyusd(event.target.checked));
        //daiPyusdSwitchLabel = isDaiNotPyusd ? "DAI" : "PYUSD";
        //dispatch(reloadPage(''));
        //const onRefresh = () => {
        //  dispatch(thunkRefreshPostageCredit({ addr: connectData.address }));
        //};
    };

    return (
            <div id="daiPyusdSwitchBoxDiv" className={coinSelectorBoxDivClass}>
              <Tooltip placement="right" title={daiPyusdSwitchTip}>
                  <FormControlLabel control={<Switch id="optionDaiPyusdSwitch" disabled={daiPyusdSwitchDisabled}
                                                     checked={isDaiNotPyusd} onChange={onDaiPyusdExecute}
                                                     size="small" color="secondary"
                                                     />}
                                    label={daiPyusdSwitchLabel} labelPlacement="top"/>
              </Tooltip>
            </div>
    );
}

export default CoinSelectorBox;
