/* ----------------------------------------------------------------------------------------------------- */
/* component shows a dialog for errors                                                                   */
/* ----------------------------------------------------------------------------------------------------- */
import React, { useState }            from "react";
import PropTypes                      from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert                          from '@mui/material/Alert';
import AlertTitle                     from '@mui/material/AlertTitle';
import Typography                     from '@mui/material/Typography';
import { styled }                     from '@mui/material/styles';

import '../css/common.css';
const common = require('../lib/common');
const ether  = require('../lib/ether');

const theme = createTheme({
    palette: {
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    },
});


// props.severity: error | warning | info | success
// props.closeAction: callback when user closes the dialog. must at least clear the message.
const ErrorDialog = (props) => {
    const message = props.message;
    const closeAction = props.closeAction;
    const severity = (typeof(props.severity) == "undefined") ? "error" : props.severity;
    const alertTitle = severity.substring(0, 1).toUpperCase() + severity.substring(1);
    const errorDialogDivClass = !!props.message ? "visible" : "hidden";

    const onClose = () => {
        console.log("ErrorDialog: got onClose()");
        if (!!closeAction) {
            closeAction();
        }
    };

    return (
            <div id="errorDialogDivOuter" className={errorDialogDivClass} >
              <div id="errorDialogDiv" className={errorDialogDivClass} >
                <ThemeProvider theme={theme}>
                  <Alert onClose={onClose}
                         severity={severity}
                         sx={{ "overflowWrap": "anywhere" }} >
                    <AlertTitle>{alertTitle}</AlertTitle>
                    {message}
                  </Alert>
                </ThemeProvider>
              </div>
            </div>
    );
}

ErrorDialog.propTypes = {
    severity: PropTypes.string,
    message: PropTypes.string.isRequired,
};

export default ErrorDialog;

/*
  action={ <Button color="inherit" size="small">OK</Button> }
*/
