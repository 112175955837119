import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import '../css/common.css';
import { connectDataSelector,
         thunkInitConnection }      from "../features/connectFeature";

const common = require('../lib/common');

/* ----------------------------------------------------------------------------------------------------- */
/* mui Button                                                                                              */
/* ----------------------------------------------------------------------------------------------------- */
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button                         from '@mui/material/Button';
import Tooltip                        from '@mui/material/Tooltip';
import { styled }                     from '@mui/material/styles';

const ConnectButton = styled(Button)({
  textTransform: 'none',
});

const colorTheme = createTheme({
    palette: {
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    },
});


let connectChangeMarkerPrev = -1;

const ConnectWalletButton = () => {

    const connectData = useSelector(connectDataSelector);
    if (connectChangeMarkerPrev != connectData.changeMarker) {
        console.log('ConnectWalletButton: change detected');
        connectChangeMarkerPrev = connectData.changeMarker;
    }

    let toolTipText = '';
    let buttonDisable = false;
    let buttonText = 'Connect to Metamask';
    const dispatch = useDispatch();
    const beenRendered = !!document.getElementById('connectDiv');
    if (!!beenRendered) {
        if (!!connectData.network) {
            buttonText = connectData.displayAddress;
            toolTipText = connectData.address;
        } else {
            buttonText = "Connect to Metamask";
            toolTipText = '';
        }
    }

    const onSubmit = () => {
        dispatch(thunkInitConnection());
    };

    return (
            <div id="connectDiv">
              <ThemeProvider theme={colorTheme}>
                <Tooltip title={toolTipText}>
                  <ConnectButton variant="contained" color="neutral" id="connectWalletButton" onClick={onSubmit} >{buttonText}</ConnectButton>
                </Tooltip>
              </ThemeProvider>
            </div>
        );
}

export default ConnectWalletButton;
